import React, { useEffect, useState } from 'react';
import './Couriers.css';
import { Modal, ModalHeader, ModalBody, Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, Form, Input, FormGroup, Dropdown, DropdownItem, Card, CardBody, CardTitle } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Status from '../Status';
import AddCourier from './AddCourier';
import EditCourier from './EditCourier';
import { Link } from 'react-router-dom';
import Loader from '../Footer/Loader';
const configData = require("../../config");



const Courier = () => {
  const[loading,setLoading]=useState(true);
  
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [viewId, setViewId] = useState('');
  const [data,setData]=useState([]);
  // const [viewData,setViewData]=useState([]);
  const [searchData,setSearchData]=useState({
    company_name: '',
    email: '',
    id: ''
  });

  const [dropdownOpenArray, setDropdownOpenArray] = useState(new Array(data.length).fill(false));

  // Function to toggle the dropdown for a specific index
  const toggleDropdown = (index) => {
    const newDropdownOpenArray = [...dropdownOpenArray];
    newDropdownOpenArray[index] = !newDropdownOpenArray[index];
    setDropdownOpenArray(newDropdownOpenArray);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModal2 = (id) => {
    setViewId(id);
    setModalOpen2(!modalOpen2);
  };

  useEffect(() => {
    const fetchList =()=>{
      axios.post(`${process.env.REACT_APP_BASE_COURIER_LIST}`)
    .then((res) => {
      setLoading(false);
      setData(res.data.data);
      if (res) {
        // Swal.fire({ title: 'Saved Successfully', text: res.msg, icon: 'Success' }); 
      } else {
        Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
      }
    })
    .catch((err) => {
      Swal.fire({ title: 'Try Again', text: err, icon: 'error' });
    });
    }
    fetchList();
  },[])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  // const editMerchant=(id)=>{
  //   axios.post(`${process.env.REACT_APP_IMAGE_MERCHANT_VIEW}`,{ id:id })
  //   .then((res) => {
  //     setViewData(res.data.data);
  //     console.log(viewData)
  //     if (res) {
  //       //Swal.fire({ title: 'Saved Successfully', text: res.msg, icon: 'Success' });
  //     } else {
  //       Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
  //     }
  //   })
  //   .catch((err) => {
  //     console.error('Error:', err);
  //   });
  // }
  const handleSearch = async(e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    console.log(searchData, 'search data');
  try {
    let url = '';
    if (name === 'company_name') {
      url = (`${process.env.REACT_APP_BASE_COURIER_LIST}?full_name=${value}`);
    } else if (name === 'email') {
      url = (`${process.env.REACT_APP_BASE_COURIER_LIST}?email=${value}`);
    } else if (name === 'id') {
      url = (`${process.env.REACT_APP_BASE_COURIER_LIST}?id=${value}`);
    }
    console.log(url, 'url');
    const response = await axios.post(url);
    setData(response.data.data);
    // console.log(response.data, 'responseda'); // Handle the response data accordingly
  } catch (error) {
    // console.error('Error searching:', error);
    // Handle error
  }
};

  return (
    <div className='side-margin'>
      <Card>
        <CardBody>
      <CardTitle className='page-title'>All Orders</CardTitle>
      <Form>
        <FormGroup className='mb-3 d-flex justify-content-between searchfiltered align-items-center'>
          <Input name='company_name' value={searchData.company_name} type='text' placeholder='Enter Brand Name' onChange={handleSearch} />
          <Input name='email' value={searchData.email} type='text' placeholder='Enter Email Address' onChange={handleSearch} />
          <Input type='text' name='id' value={searchData.id} placeholder='Enter Merchant ID' onChange={handleSearch} />
          <Button onClick={toggleModal}>Add Courier</Button>
        </FormGroup>
      </Form>

      {loading ? <Loader /> : (
        <Table bordered responsive className="custom-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>LOGO</th>
            <th>DATE</th>
            <th width="10%">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.full_name}</td>
              <td>{item.email}</td>
              <td>
                <img src={process.env.REACT_APP_IMAGE_COURIER+item.logo} alt="Courier Logo" style={{ width: '60px' }} />
              </td>
              <td>{item.updated_at}</td>
              <td className="text-nowrap">
                <div className="btn-group-ctm">
                  <span className="mr-2">
                    <Status
                      statusUrl={process.env.REACT_APP_GET_MERCHANT}
                      data={{ "merchant_id": item.service_id, "status": item.status ? 0 : 1 }}
                    />
                  </span>
                  <Button className="ctm-btn btn-sm ml-2" color="info" onClick={() => toggleModal2(item.id)} outline>Edit</Button>
                  <Dropdown isOpen={dropdownOpenArray[index]} toggle={() => toggleDropdown(index)}>
                    <DropdownToggle className='mr-0' style={{padding:"3px 11px"}} caret>
                      More
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem><Link to={`/zoning/${item.id}`}>Zoning</Link></DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
          )}

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Courier</ModalHeader>
        <ModalBody>
          <AddCourier toggleModal={toggleModal} /> 
        </ModalBody>
      </Modal>
      <Modal isOpen={modalOpen2} toggle={toggleModal2}>
        <ModalHeader toggle={toggleModal2}>Edit Courier</ModalHeader>
        <ModalBody>
          <EditCourier viewId={viewId} toggleModal={toggleModal2} />
        </ModalBody>
      </Modal>
      </CardBody>
      </Card>
    </div>
  );
};

export default Courier;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody } from 'reactstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import UrlImageDownloader from 'react-url-image-downloader';

const KycDetails = () => {
    const merchant_id = Cookies.get('merchant_id');
    const [formType, setFormType] = useState('individual');
    const [individualFormData, setIndividualFormData] = useState({
        adhar_card_no: '',
        adhar_card_file: null,
        gst_number: '',
        gst_file: null,
        pan_card_no: '',
        pan_card_file: null
    });
    const [companyFormData, setCompanyFormData] = useState({
        gst_number: '',
        gst_file: null,
        pan_card_no_director: '',
        pan_card_file_director: null,
        pan_card_no_business: '',
        pan_card_file_business: null
    });

    useEffect(() => {
        // Fetch data from the API and update state
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            // Make a GET request to fetch data from API
            const response = await axios.post(process.env.REACT_APP_BASE_MERCHANT_KYC_VIEW, { merchant_id: merchant_id });
            const responseData = response.data.data;
            console.log('responseData', responseData);
            // Update form type based on merchant type
            setFormType(responseData.merchant_type === 1 ? 'individual' : 'company');
            // Update form data with fetched values
            if (responseData?.merchant_type === 1) {
                setIndividualFormData(responseData);
            } else {
                setCompanyFormData(responseData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (formType === 'individual') {
            setIndividualFormData({ ...individualFormData, [name]: value });
        } else {
            setCompanyFormData({ ...companyFormData, [name]: value });
        }
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        if (formType === 'individual') {
            setIndividualFormData({ ...individualFormData, [name]: files[0] });
        } else {
            setCompanyFormData({ ...companyFormData, [name]: files[0] });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Create FormData object to send files
            const formDataToSend = new FormData();
            // Append form data based on form type
            if (formType === 'individual') {
                Object.entries(individualFormData).forEach(([key, value]) => {
                    formDataToSend.append(key, value);
                });
            } else {
                Object.entries(companyFormData).forEach(([key, value]) => {
                    formDataToSend.append(key, value);
                });
            }
            // Send the form data to the appropriate API endpoint
            const response = await axios.post(process.env.REACT_APP_BASE_MERCHANT_KYC_ADD, formDataToSend);
            console.log('Form submitted successfully!', response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    return (
        <div className='side-margin'>
            <Col sm="12">
            <div className="p-0 my-3 pickupPoints shadow content-wrapper border-0 card">
             <Card className="border-0">
             <CardBody>
                        <div className="page-title card-title"><b>Manage KYC</b></div>
            <Form onSubmit={handleSubmit}>
            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="type"
                        value="individual"
                        checked={formType === 'individual'}
                        onChange={() => setFormType('individual')}
                        className='form-control-inline'
                    />{' '}
                    Individual
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="type"
                        value="company"
                        checked={formType === 'company'}
                        onChange={() => setFormType('company')}
                        className='form-control-inline'
                    />{' '}
                    Company
                </Label>
            </FormGroup>


                    {/* Render individual or company form fields based on formType */}
                    {formType === 'individual' ? (
                        <div>
                             {console.log(individualFormData, 'individual')}
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="adhar_card_no">Aadhar Card No.<span className="text-danger">*</span></Label>
                                        <Input name="adhar_card_no" placeholder="Aadhar Card No." id="adhar_card_no" value={individualFormData.adhar_card_no} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='image-cheque'>
                                        <Label for="adhar_card_file">Upload Aadhar Card<span className="text-danger">*</span></Label>
                                        <Input name="adhar_card_file" type="file" className="form-control-sm form-control" id="uploadAdhar" onChange={handleFileChange} />
                                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + individualFormData.adhar_card_file} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="pan_card_no">Pan Card No.<span className="text-danger">*</span></Label>
                                        <Input name="pan_card_no" placeholder="Pan Card No." id="pan_card_no" value={individualFormData.pan_card_no} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='image-cheque'>
                                        <Label for="pan_card">Upload Pan Card<span className="text-danger">*</span></Label>
                                        <Input name="pan_card" type="file" className="form-control-sm form-control" id="uploadPan" onChange={handleFileChange} />
                                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + individualFormData.pan_card} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="adhar_card_no">GST Number<span className="text-danger">*</span></Label>
                                        <Input name="gst_number" placeholder="Enter GST Number" id="gst_number" value={individualFormData.gst_number} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='image-cheque'>
                                        <Label for="gst_file">Upload GST Or Principal Place of Business<span className="text-danger">*</span></Label>
                                        <Input name="gst_file" type="file" className="form-control-sm form-control" id="gst_file" onChange={handleFileChange} />
                                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + individualFormData.gst_file} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            <Row>
                                {console.log(companyFormData, 'company')}
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="gst_number">GST Number<span className="text-danger">*</span></Label>
                                        <Input name="gst_number" placeholder="GST Number" id="gst_number" value={companyFormData.gst_number} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className='image-cheque'>
                                        <Label for="gst_file">Upload GST image<span className="text-danger">*</span></Label>
                                        <Input name="gst_file" type="file" className="form-control-sm form-control" id="uploadgst" />
                                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + companyFormData.gst_file} />

                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="pan_card_no_director">Pan Card No. / Aadhar Card No.<span className="text-danger">*</span></Label>
                                        <Input name="pan_card_no_director" placeholder="Pan Card No. / Aadhar Card No." id="pan_card_no_director" value={companyFormData.pan_card_no_director} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="pan_card_director">Upload Pan Card / Upload Aadhar Card<span className="text-danger">*</span></Label>
                                        <Input name="pan_card_director" type="file" className="form-control-sm form-control" id="pan_card_director" />
                                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + companyFormData.pan_card} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="pan_card_no_director">Pan Card No. of Business<span className="text-danger">*</span></Label>
                                        <Input name="pan_card_no_director" placeholder="Pan Card No. / Aadhar Card No." id="pan_card_no_director" value={companyFormData.pan_card_no_director} onChange={handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="pan_card_director">Upload Pan Card of Business<span className="text-danger">*</span></Label>
                                        <Input name="pan_card_director" type="file" className="form-control-sm form-control" id="pan_card_director" />
                                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + companyFormData.pan_card} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <Button type="submit">Submit</Button>
                </Form>
                </CardBody>
                </Card>  
                </div>
                </Col>          
        </div>
    );
};

export default KycDetails;


import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from "reactstrap";
import './Sidebar.css';

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);


  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggle2 = () => {
    setIsOpen2(!isOpen2);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white p-0 pt-3" expand="md" id="sidenav-main">
  <Container fluid>
    <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
      <span className="navbar-toggler-icon" />
    </button>
    <NavbarBrand style={{padding:'20px,20px,0px'}}>
      <img alt='logo' className="navbar-brand-img" src={require("../../assets/img/brand/logo1.png")} />
    </NavbarBrand>
    <Collapse navbar isOpen={collapseOpen} className="collapse-new">
      <Nav vertical className="flex-column">
        <NavItem>
          <NavLink tag={Link} to='/'>
            <div>
            <i className="ni ni-tv-2" /> Dashboard
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#" onClick={toggle2}>
            <div>
            <i className="ni ni-bag-17" /> My Orders
            </div>
            {isOpen2 ? <i className="fa fa-chevron-up ml-2" aria-hidden="true"></i> : <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>}
          </NavLink>
          <Collapse isOpen={isOpen2}>
            <Nav vertical>
              <NavItem>
                <NavLink tag={Link} to='/all-orders'>
                  <div>
                  <i className="ni ni-bullet-list-67" /> View All Orders
                  </div>
                  </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/create-order'>
                  <div>
                  <i className="ni ni-fat-add" /> Create New Order
                  </div>
                  </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/pickup-points'>
            <div>
            <i className="ni ni-pin-3" /> Pickup Points
            </div>
           </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/merchant'>
            <div>
            <i className="ni ni-shop" /> Merchants
            </div>
            </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/courier'>
            <div><i className="ni ni-delivery-fast" /> Courier</div></NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/bank-details'><div><i className="ni ni-credit-card" /> Bank Details</div></NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/billing-details'><div><i className="ni ni-money-coins" /> Billing Details</div></NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/kyc-details'><div><i className="ni ni-single-02" /> KYC Details</div></NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to='/serviceability'><div><i className="ni ni-settings-gear-65" /> Serviceability</div></NavLink>
        </NavItem>
      </Nav>
    </Collapse>
  </Container>
</Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

import React, { useEffect, useState } from 'react'
import { Row,Input, Col, Card, CardBody,Button, CardTitle, Spinner, FormGroup,Form, CardHeader, Table,Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import Status from '../Status';
const PickupPoints = () => {
  const [loader, setLoader] = useState(false);
  const [datas,setDatas]=useState([]);

  // const { id, contact_name, pickup_phone, created_at, updated_at, status, is_default } = datas;

  console.log(datas,'ed')

  const merchant_id=Cookies.get('merchant_id');

  useEffect(()=>{
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_ALL}`,
      data: {'merchant_id':merchant_id},
  }).then((res) => {
      setLoader(false);
      if (res?.data?.data!==0) {
          setDatas(res?.data?.data)
      }
      else {
          Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
      }
  }).catch((err) => {
      setLoader(false);
  });
  },[])

  

    const markas = (data) => {
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_IS_DEFAULT,
      data: data,
    }).then((res) => {
          setLoader(false);
          if (res.data.error === false) {
              Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
              window.location.reload();
          }
         
      })
      .catch((err) => {
        setLoader(false);
      });
};

const handleRefresh=()=>{
    window.location.reload();
}
  return (
    <div className='side-margin'>
      <Col sm="12">
            <div className="p-0 my-3 pickupPoints shadow content-wrapper border-0 card">
                <Card className="border-0">
                    <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                        <div className="page-title card-title"><b>Manage Pickup Points</b></div>
                        <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                            <Link className="btn-text btn-light mx-1" to='/pickup-points/add'>
                                <span className="icon mr-2">
                                    {/* <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                                    </svg> */}
                                </span>
                                <span>Add Pickup Point</span>
                            </Link>
                        </div>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="table-responsive">
                            <table className="text-center custom-table table">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Pickup ID</th>
                                        <th className="text-nowrap">Pickup Point/Contact Name</th>
                                        <th className="text-nowrap">Mobile</th>
                                        <th className="text-nowrap">Created</th>
                                        <th className="text-nowrap">Updated</th>
                                        <th className="text-nowrap">Status</th>
                                        <th className="text-nowrap">Default</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {datas && datas.map((val)=>(
                                    <tr key={val.id}>
                                    <td>{val.id}</td>
                                    <td><Link to={`edit/${val.id}`}><span>{val.contact_name}</span></Link></td>
                                    <td>{val.pickup_phone}</td>
                                    <td>{val.created_at}</td>
                                    <td>{val.updated_at}</td>
                                    <td><span onClick={handleRefresh}><Status statusUrl={process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS} listId={val.id} valStatus={val.status} data={{"pickup_point_id":val.id, "status": val.status === 1 ? "0" : 1}} /></span></td>
                                    <td><span><Button outline color = { `${val.is_default===1 ? "success": "danger"}` } className = "ctm-btn btn-sm btn-active text-nowrap" onClick = { (data) => markas(data={"pickup_point_id":val.id,"merchant_id":val.merchant_id, "is_default": val.is_default === 1 ? 0 : 1}) } > { val.is_default === 1 ? 'Default' : 'Mark As Default' } </Button></span></td>
                                  </tr>
                                  ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Col>
    </div>
  )
}

export default PickupPoints

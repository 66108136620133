import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Table,Form, Input, Toast, CardBody, Card, CardTitle } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { download_delay } from '../../config';
import Loader from '../Footer/Loader';

const Serviceability = () => {
  const [data, setData] = useState(null);
  const [loadings, setLoadings] = useState(true);
  const [error, setError] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const [excelModal, setExcelModal] = useState(false);
  const [buttonDisabled, setButtonDisables] = useState(false);
  const [formSearch,setFormSearch] = useState({
    'destination_pincode': '',
    'source_pincode': ''
  });
  const [errors, setErrors] = useState({
    destination_pincode: '',
    source_pincode: ''
  });

const navigate = useNavigate();
  const toggleExcelModal = (id) => {
    console.log(id, 'id');
    setSelectedId(id)
    setExcelModal(!excelModal);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL_SERVICE_VIEW);
        setData(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoadings(false);
      }
    };

    fetchData();
  }, []);
  if (error) return <div>Error: {error.message}</div>;

  const handleExcelUpload = (event) => {
    // Handle form submission if needed
    event.preventDefault();
    console.log(selectedId,'selected id');
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('courier_id', selectedId);

    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL_SERVICE_UPLOAD, formData,{
            headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setData(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoadings(false);
      }
    }
    fetchData();

    // Your logic for handling form submission (upload) goes here
  };

  const handleFileInput = (event) => {
    setSelectedFile(event.target.files[0]);
    
  };

  const notify = (courier_id, type) => {
    setButtonDisables(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_SERVICE_DOWNLOAD}`,
      data: {courier_id: courier_id},
    }).then((res) => {
      setButtonDisables(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoadings(false);
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.data !== '') {
            setTimeout(function () {
              navigate("/downloads");
              setLoadings(false);
            }, download_delay);
          } else {
            setLoadings(false);
            Swal.fire({ icon: 'warning', text: res.data.msg, title: 'Serviceability' })
          }
        }
      }
    }).catch((err) => {
      setLoadings(false);
    });
  };
  const handleFilterChange=(e)=>{
      const { name, value } = e.target;
      setFormSearch({
          ...formSearch,
          [name]: value
      })
      setErrors({
        ...errors,
        [name]: ''
      });
  }
  const handleFilter = (event) => {
    event.preventDefault();
    let valid = true;
    const newErrors = {};

    if (!formSearch.destination_pincode) {
      valid = false;
      newErrors.destination_pincode = 'Destination pincode is required.';
    }

    if (!formSearch.source_pincode) {
      valid = false;
      newErrors.source_pincode = 'Source pincode is required.';
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }
    console.log(formSearch, 'form search');
    const response=axios.post(process.env.REACT_APP_BASE_URL_SERVICE_SEARCH,formSearch)
    .then((response)=>{
        setData(response.data.data)
    }).catch((error)=>{
        Swal.fire({ icon: 'error', text: error, title: 'Error' })
    })

  }
  return (
    <div className='side-margin'>
      {loadings && <Loader />}
      <Card>
        <CardBody>
        <CardTitle className="page-title"><b>Serviceability</b></CardTitle>
        <Form onSubmit={handleFilter}>
            <FormGroup row className='align-items-end'>
                <Col sm={4}>
                    <Input placeholder='Destination Pincode' type="text" name="destination_pincode" id="destination_pincode" onChange={handleFilterChange} />
                    {errors.destination_pincode && <p style={{ color: 'red' }} className="error">{errors.destination_pincode}</p>}
                </Col>
                <Col sm={4}>
                    <Input placeholder='Source Pincode' type="text" name="source_pincode" id="source_pincode" onChange={handleFilterChange} />
                    {errors.source_pincode && <p style={{ color: 'red' }} className="error">{errors.source_pincode}</p>}
                </Col>
                <Button type="submit">Submit</Button>
            </FormGroup>
            
        </Form>
      <Table bordered responsive>
        <thead>
          <tr>
            <th width="1%">Courier ID</th>
            <th>Courier</th>
            <th>COD</th>
            <th>Prepaid</th>
            <th>Pickup</th>
            <th>Last Updated On</th>
            <th>Download</th>
            <th>Upload</th>
          </tr>
        </thead>
        <tbody>
          {data && data.map((data,index)=>(
            <tr key={index}>
            <td>{data?.courier_id}</td>
            <td>{data?.courier?.full_name}</td>
            <td>{data?.totalCOD}</td>
            <td>{data?.totalPrepaid}</td>
            <td>{data?.totalPickup}</td>
            <td>{data?.updated_at_formatted}</td>
            <td>
            {data.totalCOD || data.totalPrepaid || data.totalPickup ? <Button outline color="primary" className="text-nowrap" size="sm" download disabled={buttonDisabled} onClick={() => notify(data.courier_id, 'courier')}><span className="icon mr-2"></span><span>Download Pincode</span></Button> : ''}
            </td>
            <td>
              <Button size="sm" onClick={()=>toggleExcelModal(data?.courier_id)} color="success">Upload</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Modal isOpen={excelModal} toggle={toggleExcelModal} scrollable={true}>
        <ModalHeader toggle={toggleExcelModal}>Upload Serviceability</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleExcelUpload}>
            <p className="mb-2">
              <span className="mr-2">Upload all Your Pincode via XLSX</span>
            </p>
            <p className="mb-2">
              <span className="mr-2">Please download below given template</span>
            </p>
            <p className="mb-2">
              <span className="mr-2">Remove sample data and upload your data</span>
            </p>
            <p className="mb-2">
              <span className="mr-2">Make sure you atleast fill mandatory fields.</span>
            </p>

            <Row className="m-0 pb-1">
              <Col sm={12} className="p-0">
                <p className="mb-2">
                  <span className="mr-2">
                  <Link target="_blank" download to="/format/serviceability.xlsx" className=""><b>Download</b></Link>
                  </span>
                </p>
              </Col>
              <Col sm={12} className="m-auto pt-1 p-0">
                <FormGroup>
                  <Input accept=".xlsx" type="file" id="excelUpload" onChange={handleFileInput} />
                  {/* <Label className="custom-file-label" for="excelUpload">Choose file</Label> */}
                  {/* <Input type="file" name="cancel_cheque" id="cancel_cheque" onChange={handleFileChange} disabled={disableForm} /> */}

                  {/* Error message display */}
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      </CardBody>
      </Card>
    </div>
  );
};

export default Serviceability;

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import configData, { baseString } from "../../config";
import Swal from 'sweetalert2';


const Zoning = () => {
    const [data,setData]=useState([]);
    const [error, setError] = useState(null);
    const params=useParams();
    const [loading,setLoading] =useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const [excelModal, setExcelModal] = useState(false);
  const [pincode, setPincode] = useState('');

  const handlePincode = (e) => {
    setPincode(e.target.value);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_BASE_COURIER_ZONING}?pincode=${pincode}`;
    
    axios.post(apiUrl,{'courier_id': params.courierId})
      .then(response => {
        // Handle API response
        console.log(response.data.data);
        setData(response.data.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }

    console.log(params,'params')
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(process.env.REACT_APP_BASE_COURIER_ZONING, {'courier_id': params.courierId});
            setData(response.data.data);
            console.log(response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, [params.id]);

      const toggleExcelModal = (id) => {
        console.log(id, 'id');
        setSelectedId(id)
        setExcelModal(!excelModal);
      }

      const handleFileInput = (event) => {
        setSelectedFile(event.target.files[0]);
      };

      const handleExcelUpload = (event) => {
        // Handle form submission if needed
        event.preventDefault();
        console.log(selectedId,'selected id');
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('courier_id', selectedId);
    
        const fetchData = async () => {
          try {
            const response = await axios.post(process.env.REACT_APP_BASE_COURIER_ZONING_UPLOAD, formData,{
                headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setData(response.data.data);
            console.log(response.data.data);
          } catch (error) {
            setError(error);
          } finally {
            setLoading(false);
          }
        }
        fetchData();
    
        // Your logic for handling form submission (upload) goes here
      };

      const [modalOpen, setModalOpen] = useState(false);
      const [selectedItemId, setSelectedItemId] = useState(null);
      const toggleModal = (itemIdS) => {
        setSelectedItemId(itemIdS);
        setModalOpen(!modalOpen);
      };
      const editZoning = (itemIdS) =>{
        console.log(itemIdS, 'itemIdS');
        toggleModal(itemIdS);
      }

// for modal edit and view

const [formDataS, setFormDataS] = useState({
  pincode: '',
  zone_id: '',
  is_extended: 0, 
  is_special_destination: 0,
  is_metro: 0,
  is_roi: 0
});

useEffect(() => {
  if (modalOpen) {
    // Fetch data for the given itemId
    fetchDataS();
  }
}, [modalOpen]);

const fetchDataS = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_COURIER_ZONING_VIEW}/${String(selectedItemId)}`);
    if (response) {
      // const data = await response.json();
      // Update form data with fetched values
      setFormDataS({
        pincode: response.data.pincode,
        zone_id: response.data.zone_id,
        is_extended: String(response.data.is_extended),
        is_special_destination: String(response.data.is_special_destination),
        is_metro: String(response.data.is_metro),
        is_roi: String(response.data.is_roi)
      });
    } else {
      console.error('Failed to fetch data');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const handleChangeS = (e) => {
  const { name, value } = e.target;
  setFormDataS({ ...formDataS, [name]: value });
};

const handleSubmitS = async (e) => {

  const updatedData = {};
  for (const key in formDataS) {
    updatedData[key] = String(formDataS[key]);
  }

  e.preventDefault();
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_COURIER_ZONING_UPDATE}/${String(selectedItemId)}`, updatedData);
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
      })
      // Success message or redirection
      console.log('Data updated successfully');
    } else {
      console.error('Failed to update data');
    }
  } catch (error) {
    console.error('Error updating data:', error);
  }
};
  return (
    <div className='side-margin'>
      <Row className='align-items-start pl-3'>
      <Form onSubmit={handleFilter} className='d-flex align-items-start'>
        <FormGroup className='mr-3'>
          <Input type='text' id='pincode' placeholder='Enter Pincode' onChange={handlePincode} />
        </FormGroup>
        <Button className='mr-3' color="primary" type="submit">Search</Button>
        </Form>
        <Button onClick={()=>toggleExcelModal(params.courierId)} color="success">Upload Pincode</Button>
        </Row>
      <Table striped>
      <thead>
        <tr>
          <th>PINCODE</th>
          <th>EXTENDED</th>
          <th>SPECIAL DEST.</th>
          <th>METRO</th>
          <th>ROI</th>
          <th>CREATED</th>
          <th>MODIFIED</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, index) => (
           <tr key={index}>
           <td>{item.pincode}</td>
           <td>{item.is_extended ? 'Yes' : 'No'}</td>
           <td>{item.is_special_destination ? 'Yes' : 'No'}</td>
           <td>{item.is_metro ? 'Yes' : 'No'}</td>
           <td>{item.is_roi ? 'Yes' : 'No'}</td>
           <td>{item.created_at}</td>
           <td>{item.updated_at}</td>
           <td><Button onClick={() => editZoning(item.id)} color="primary">Edit</Button></td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Modal isOpen={excelModal} toggle={toggleExcelModal} scrollable={true}>
        <ModalHeader toggle={toggleExcelModal}>Upload Pincodes</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleExcelUpload}>
            <p className="mb-2">
              <span className="mr-2">Upload all Your Pincode via XLSX</span>
            </p>
            <p className="mb-2">
              <span className="mr-2">Please download below given template</span>
            </p>
            <p className="mb-2">
              <span className="mr-2">Remove sample data and upload your data</span>
            </p>
            <p className="mb-2">
              <span className="mr-2">Make sure you atleast fill mandatory fields.</span>
            </p>

            <Row className="m-0 pb-1">
              <Col sm={12} className="p-0">
                <p className="mb-2">
                  <span className="mr-2">
                  <Link target="_blank" download to="/format/courier_zoning.xlsx" className=""><b>Download</b> </Link>
                  </span>
                </p>
              </Col>
              <Col sm={12} className="m-auto pt-1 p-0">
                <FormGroup>
                  <Input accept=".xlsx" type="file" id="excelUpload" onChange={handleFileInput} />
                  {/* <Label className="custom-file-label" for="excelUpload">Choose file</Label> */}
                  {/* <Input type="file" name="cancel_cheque" id="cancel_cheque" onChange={handleFileChange} disabled={disableForm} /> */}

                  {/* Error message display */}
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalOpen} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>Edit Item</ModalHeader>
        <ModalBody>
        <Form onSubmit={handleSubmitS}>
  <Row className="mx-0 editModal">
    <Col sm={12}>
      <FormGroup>
        <Label for="pincode">Enter Pincode</Label>
        <Input type="text" value={formDataS.pincode} onChange={handleChangeS} placeholder="Pincode" id="pincode" />
      </FormGroup>
    </Col>
    <Col sm={12}>
      <FormGroup>
        <Label for="selectZone">Select Zone</Label>
        <Input type="select" id="selectZone" name="zone_id" value={formDataS.zone_id} onChange={handleChangeS}>
          <option>Select Service</option>
          {Object.entries(configData.zone_id).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
          ))}
        </Input>
      </FormGroup>
    </Col>
    <Col sm={12}>
        <FormGroup>
          <legend className="col-form-label">Is Extended</legend>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="is_extended" value="1" checked={formDataS.is_extended === '1'} onChange={handleChangeS} />
              Yes
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="is_extended" value="0" checked={formDataS.is_extended === '0'} onChange={handleChangeS} />
              No
            </Label>
          </FormGroup>
        </FormGroup>
      </Col>

      <Col sm={12}>
          <FormGroup>
            <legend className="col-form-label">Is ROI</legend>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="is_roi" value="1" checked={formDataS.is_roi === '1'} onChange={handleChangeS} />
                Yes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="is_roi" value="0" checked={formDataS.is_roi === '0'} onChange={handleChangeS} />
                No
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col sm={12}>
          <FormGroup>
            <legend className="col-form-label">Is Special Destination</legend>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="is_special_destination" value="1" checked={formDataS.is_special_destination === '1'} onChange={handleChangeS} />
                Yes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="is_special_destination" value="0" checked={formDataS.is_special_destination === '0'} onChange={handleChangeS} />
                No
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col sm={12}>
          <FormGroup>
            <legend className="col-form-label">Is Metro</legend>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="is_metro" value="1" checked={formDataS.is_metro === '1'} onChange={handleChangeS} />
                Yes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="is_metro" value="0" checked={formDataS.is_metro === '0'} onChange={handleChangeS} />
                No
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
  </Row>
  <Button className="ctm-btn btn-sm" color="primary" type="submit">Save</Button>
</Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Zoning

import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';

const Status = (props) => {
    const [valStatus, setValStatus] = useState(props.valStatus);

    const handleStatusUpdate = () => {
        axios({
            method: "post",
            url: `${props.statusUrl}`,
            data: props.data,
        })
        .then((res) => {
            if (res.data.error === false) {
                // Update the local status state
                setValStatus(valStatus === 1 ? 0 : 1);
                // Optionally show a success message
                Swal.fire({ title: 'Success', text: 'Status updated successfully', icon: 'success' });
            } else {
                // Optionally show an error message
                Swal.fire({ title: 'Error', text: 'Failed to update status', icon: 'error' });
            }
        })
        .catch((error) => {
            // Handle error
            console.error('Error updating status:', error);
            // Optionally show an error message
            Swal.fire({ title: 'Error', text: 'Failed to update status', icon: 'error' });
        });
    };

    return (
        <Button
            outline
            color={`${valStatus === 1 ? "success" : "danger"}`}
            className="ctm-btn btn-sm btn-active"
            onClick={handleStatusUpdate}
        >
            {valStatus === 1 ? 'Active' : 'InActive'}
        </Button>
    );
}

export default Status;

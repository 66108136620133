import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container
  } from "reactstrap";
import Swal from "sweetalert2";
  
  const AdminLogin = () => {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

const handleLogin=()=>{
  const loginData = {
    email: email,
    password: password
  };
  console.log(loginData);

  axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL_ADMIN_LOGIN}`,
    data: loginData,
}).then((res) => {
    setLoader(false);
    Cookies.set('token',res?.data?.accessToken);
    Cookies.set('user-type',res?.data?.data?.user_type);
    Cookies.set('merchant_id',res?.data?.data?.id);
    Cookies.set('logistify_merchant_id',res?.data?.data?.logistify_merchant_id);
    Cookies.set('status',res?.data?.data?.status);
    if (res) {
      // Swal.fire({ title: 'Login success', text: res.msg, icon: 'Success', })
      navigate(res?.data?.data?.user_type === "Merchant" ? '/' : '/dashboard');
  }
    else {
        Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
    }
}).catch((err) => {
    setLoader(false);
});
  setEmail('');
  setPassword('')
}
    return (
      <>

<div className="pt-4 pb-6" style={{backgroundColor:'#172b4d'}}>
<Container className="px-4 d-flex align-items-center">
          <Col lg="7" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-3">
              <div className="text-muted text-center mt-2 mb-3">
                <h1 style={{color:'#8898aa'}}><b>Admin</b> Sign in </h1>
                <h2 style={{color:'#8898aa'}}>Welcome to Logistics !</h2>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
              <p>Sign in with credentials</p>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button onClick={handleLogin} className="my-4" color="primary" type="button">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light text-left"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <p>Forgot password?</p>
              </a>
            </Col>
          </Row>
        </Col>
        <Col>
        <img
            alt="..."
            src={
                require("../../assets/img/brand/doc.png")
            }
            />
        </Col>
        </Container>
        </div>
      </>
    );
  };
  
  export default AdminLogin;
  
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Swal from 'sweetalert2';

const EditMerchant = (props) => {
    console.log(props, "props")
    const [viewData, setViewData] = useState(null);
    const [formData, setFormData] = useState({
        company_name: "",
        email: "",
        mobile: "",
        brand_name: "",
        password: "",
        max_liability_per_shipment: "",
        freight_type: "",
        billing: "",
        status: "",
        wallet_balance: "",
        max_cod_value_per_order: "",
        remittance_tat_period: "",
        ship_status: "",
        document_status: "",
      });
  useEffect(() => {
    fetchData();
  }, []);

  console.log(formData, "formdata")

  const fetchData = async () => {
    try {
        axios.post(`${process.env.REACT_APP_BASE_MERCHANT_VIEW}`, { id: props.viewId })
        .then((res) => {
          if (res) {
            const data = res.data.data;
            setFormData({
                ...data,
                billing: data.billing || "1"
            });
            setViewData(data);
          } else {
            Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
          }
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (viewData) {
      setFormData({
        id: String(props.viewId),
        ship_status: String(viewData.ship_status) || viewData.ship_status,
        admin_id:'1',
        document_status: String(viewData.document_status) || viewData.document_status,
        company_name: viewData.company_name,
        email: viewData.email,
        mobile: viewData.mobile,
        brand_name: viewData.brand_name,
        password: viewData.password,
        max_liability_per_shipment: viewData.max_liability_per_shipment,
        freight_type: String(viewData.freight_type) || viewData.freight_type,
        billing: String(viewData.billing) || viewData.billing,
        status: String(viewData.status) || viewData.status,
        wallet_balance: viewData.wallet_balance,
        max_cod_value_per_order: viewData.max_cod_value_per_order,
        remittance_tat_period: viewData.remittance_tat_period
      });
    }
  }, [viewData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};



const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "formdata")
    try {
        axios.post(`${process.env.REACT_APP_BASE_MERCHANT_EDIT}`, formData)
        .then((res) => {
            if (res) {
                Swal.fire({ title: 'Success', text: 'Data submitted successfully', icon: 'success' });
            } else {
              Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
            }
          })
    } catch (error) {
        console.error('Error submitting data:', error);
        Swal.fire({ title: 'Error', text: 'Failed to submit data', icon: 'error' });
    }
};

  return (
    <Form onSubmit={handleSubmit} className="" noValidate>
      <div className="mx-0 justify-content-start addModal row">
        <FormGroup className="col-sm-4">
          <Label for="companyName_add">Enter Company Name<span className="text-danger">*</span></Label>
          <Input type="text" name="company_name" id="companyName_add" placeholder="Enter Company Name*" value={formData.company_name} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="email_add">Enter Email<span className="text-danger">*</span></Label>
          <Input type="email" name="email" id="email_add" placeholder="Enter Email*" value={formData.email} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="brandName_add">Enter Brand Name<span className="text-danger">*</span></Label>
          <Input type="text" name="brand_name" id="brandName_add" placeholder="Enter Brand Name*" value={formData.brand_name} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="contact_add">Enter Mobile Number<span className="text-danger">*</span></Label>
          <Input type="text" name="mobile" id="contact_add" placeholder="Enter Phone Number*" value={formData.mobile} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="walletBalance_add">Enter Wallet Balance<span className="text-danger">*</span></Label>
          <Input type="text" name="wallet_balance" id="walletBalance_add" placeholder="Enter Wallet Balance*" value={formData.wallet_balance} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="maxLiabShip_add">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
          <Input type="text" name="max_liability_per_shipment" id="maxLiabShip_add" placeholder="Enter Max Liability Shipment*" value={formData.max_liability_per_shipment} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="maxCodVal_add">Enter Max COD Value <small>(per order)</small></Label>
          <Input type="text" name="max_cod_value_per_order" id="maxCodVal_add" placeholder="Enter Max COD Value*" value={formData.max_cod_value_per_order} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="password_add">Enter Password<span className="text-danger">*</span></Label>
          <Input type="password" name="password" id="password_add" placeholder="Enter Password*" value={formData.password} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="confirmpass_add">Enter Confirm Password<span className="text-danger">*</span></Label>
          <Input type="password" name="confirm_password" id="confirmpass_add" placeholder="Enter Confirm Password*" value={formData.confirm_password} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="uploadLogo_add">Upload Brand logo</Label>
          <Input type="file" name="brandlogo" id="uploadLogo_add" accept=".jpg, .jpeg, .png" onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="remittance_tat_period_add">Remittance TAT Period<span className="text-danger">*</span></Label>
          <Input type="text" name="remittance_tat_period" id="remittance_tat_period_add" placeholder="Enter Remittance TAT Period*" value={formData.remittance_tat_period} onChange={handleChange} />
        </FormGroup>
        {/* <FormGroup className="col-sm-4">
          <Label for="refer_by_add">Refer By</Label>
          <Input type="text" name="refer_by" id="refer_by_add" placeholder="Enter Refer By" value={formData.refer_by} onChange={handleChange} />
        </FormGroup> */}
        
          <FormGroup className="col-sm-4">
                <div className="pl-0 form-check d-flex flex-column">
                    <Label className="mb-1">Billing<span className="text-danger">*</span></Label>
                    <div className="form-check2 d-flex flex-column">
                        <div className="form-check">
                            <Input className="mr-2" type="radio" name="billing" id="preBill_add" value="1" checked={formData.billing === '1'} onChange={handleChange} />
                            <Label for="preBill_add" className="ml-1 mb-0">Prepaid Billing</Label>
                        </div>
                        <div className="form-check">
                            <Input className="mr-2" type="radio" name="billing" id="postBill_add" value="2" checked={formData.billing === '2'} onChange={handleChange} />
                            <Label for="postBill_add" className="ml-1 mb-0">Postpaid Billing</Label>
                        </div>
                    </div>
                </div>
          </FormGroup>
        <FormGroup className="col-sm-4">
          <div className="pl-0 form-check d-flex flex-column">
            <Label className="mb-1">Freight Type<span className="text-danger">*</span></Label>
            <div className='form-check2 d-flex flex-column'>
              <div className="form-check">
                <Input className="mr-2" type="radio" name="freight_type" id="fixCharge_add" value="1" checked={formData.freight_type === '1'} onChange={handleChange} />
                <Label for="fixCharge_add" className="ml-1 mb-0">Fixed Charge</Label>
              </div>
              <div className="form-check">
                <Input className="mr-2" type="radio" name="freight_type" id="varCharge_add" value="2" checked={formData.freight_type === '2'} onChange={handleChange} />
                <Label for="varCharge_add" className="ml-1 mb-0">Variables Charge</Label>
              </div>
            </div>
          </div>
        </FormGroup>
        {/* <FormGroup className="col-sm-4">
          <div className="pl-0 form-check d-flex flex-column">
            <Label className="mb-1">Status<span className="text-danger">*</span></Label>
            <div className='form-check2 d-flex flex-column'>
              <div className="form-check">
                <Input className="mr-2" type="radio" name="status" id="active_add" value="1" checked={formData.status === '1'} onChange={handleChange} />
                <Label for="active_add" className="ml-1 mb-0">Active</Label>
              </div>
              <div className="form-check">
                <Input className="mr-2" type="radio" name="status" id="inactive_add" value="0" checked={formData.status === '0'} onChange={handleChange} />
                <Label for="inactive_add" className="ml-1 mb-0">InActive</Label>
              </div>
            </div>
          </div>
        </FormGroup> */}

        <FormGroup check className="pl-0">
              <Label>Ship Status<span className="text-danger">*</span></Label>
              <div>
                  <span className="form-check2 mr-2">
                      <input className="mx-2" name="ship_status" type="radio" value="1" checked={formData.ship_status === '1'} onChange={handleChange} />
                      <Label for="active_add">Active</Label>
                  </span>
                  <span className="form-check2">
                      <input className="mx-2" name="ship_status" type="radio" value="0" checked={formData.ship_status === '0'} onChange={handleChange} />
                      <Label for="inactive_add">InActive</Label>
                  </span>
              </div>
          </FormGroup>
          <FormGroup check className="pl-0">
              <Label>Document Status<span className="text-danger">*</span></Label>
              <div>
                  <span className="form-check2 mr-2">
                      <input className="mx-2" name="document_status" type="radio" value="1" checked={formData.document_status === '1'} onChange={handleChange} />
                      <Label for="validate">Validated</Label>
                  </span>
                  <span className="form-check2">
                      <input className="mx-2" name="document_status" type="radio" value="0" checked={formData.document_status === '0'} onChange={handleChange} />
                      <Label for="not_validate">Not Validated</Label>
                  </span>
              </div>
          </FormGroup>
          <FormGroup check className="pl-0">
              <Label>Status<span className="text-danger">*</span></Label>
              <div>
                  <span className="form-check2 mr-2">
                      <input className="mx-2" name="status" type="radio" value="1" checked={formData.status === '1'} onChange={handleChange} />
                      <Label for="edt_active_add">Active</Label>
                  </span>
                  <span className="form-check2">
                      <input className="mx-2" name="status" type="radio" value="0" checked={formData.status === '0'} onChange={handleChange} />
                      <Label for="edt_inactive_add">InActive</Label>
                  </span>
              </div>
          </FormGroup>

        <FormGroup className="text-right col-sm-12">
          <Button className="btn-text btn-light mx-1">Save</Button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default EditMerchant;

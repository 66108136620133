import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, Col } from 'reactstrap';
import axios from 'axios';
import './Couriers.css';
import Swal from 'sweetalert2';
const configData = require("../../config");

const AddCourier = ({ toggleModal }) => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    brand_name: '',
    tat_in_days: '',
    logo: null,
    weight_category: '',
    movement_type_id: '',
    volumetric_value: '',
    max_liability_per_shipment: '', // Assuming default is 'Custom'
    status: '1',
    service_id:"1",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === 'logo') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0], // Store the file object itself
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true); // Set loader to true while waiting for the response
  
      const formDataToSend = new FormData();
      formDataToSend.append('full_name', formData.full_name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('brand_name', formData.brand_name);
      formDataToSend.append('tat_in_days', formData.tat_in_days);
      formDataToSend.append('logo', formData.logo); // Append the logo file
      formDataToSend.append('weight_category', formData.weight_category);
      formDataToSend.append('movement_type_id', formData.movement_type_id);
      formDataToSend.append('volumetric_value', formData.volumetric_value);
      formDataToSend.append('max_liability_per_shipment', formData.max_liability_per_shipment);
      formDataToSend.append('status', formData.status);
      formDataToSend.append('service_id', formData.service_id);
  
      const res = await axios.post(`${process.env.REACT_APP_BASE_COURIER_CREATE}`, formDataToSend);
      setLoader(false); // Reset loader once the response is received
      if (res) {
        Swal.fire({ title: 'Saved Successfully', text: res.data.msg, icon: 'success' });
      } else {
        Swal.fire({ title: 'Try Again', text: res.data.msg, icon: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setLoader(false); // Reset loader in case of an error
      // Handle error accordingly, show an alert, toast, or any other UI indication
      Swal.fire({ title: 'Error', text: 'An error occurred while processing your request.', icon: 'error' });
    }
  };
  return (
    <Form onSubmit={handleSubmit} className="">
    <div className="mx-0 justify-content-start addModal row">
      <Col sm={4}>
        <FormGroup>
          <Label for="Courier Name">Enter Courier Name<span className="text-danger">*</span></Label>
          <Input type="text" name="full_name" id="Courier Name" placeholder="Enter Courier Name*" value={formData.full_name} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="email">Enter Email<span className="text-danger">*</span></Label>
          <Input type="email" name="email" id="email" placeholder="Enter Email*" value={formData.email} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="brand_name">Enter Brand Name<span className="text-danger">*</span></Label>
          <Input type="text" name="brand_name" id="brand_name" placeholder="Enter Brand Name*" value={formData.brand_name} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="tat_in_days">TAT In Days<span className="text-danger">*</span></Label>
          <Input type="text" name="tat_in_days" id="tat_in_days" placeholder="Enter TAT In Days*" value={formData.tat_in_days} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
      <FormGroup>
        <Label for="logo">Upload Logo<span className="text-danger">*</span></Label>
        <Input type="file" name="logo" id="logo" onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="selectWeightCategory">Weight Category <small>(In Kgs.)</small><span className="text-danger">*</span></Label>
          <Input type="select" className="form-control" name="weight_category" id="selectWeightCategory" value={formData.weight_category} onChange={handleChange}>
            <option value="">Select Weight Category</option>
            {Object.entries(configData.weight).map(([key, value]) => (
                <option value={value}>{value}</option>
            ))}
          </Input>
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="selectMovementType">Movement Type<span className="text-danger">*</span></Label>
          <Input type="select"  className="form-control" name="movement_type_id" id="selectMovementType" value={formData.movement_type_id} onChange={handleChange}>
          <option value="">Select Movement Type</option>
                {Object.entries(configData.movement_type).map(([key, value]) => (
                    <option value={key}>{value}</option>
                ))}
          </Input>
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="volumetric_value">Enter Volumetric Value<span className="text-danger">*</span></Label>
          <Input type="text" name="volumetric_value" id="volumetric_value" placeholder="Enter Volumetric Value*" value={formData.volumetric_value} onChange={handleChange} />
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="maxLiaShip">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
          <Input type="text" name="max_liability_per_shipment" id="maxLiaShip" placeholder="Enter Max Liability Shipment*" value={formData.max_liability_per_shipment} onChange={handleChange} />
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Status<span className="text-danger">*</span></Label>
          <div className='form-check d-flex flex-column'>
            <span className="form-check2 mr-2">
              <Input type="radio" name="status" id="active" value="1" checked={formData.status === '1'} onChange={handleChange} />
              <Label className="mx-2" for="active">Active</Label>
            </span>
            <span className="form-check2">
              <Input type="radio" name="status" id="inactive" value="0" checked={formData.status === '0'} onChange={handleChange} />
              <Label className="mx-2" for="inactive">Inactive</Label>
            </span>
          </div>
        </FormGroup>
      </Col>
      <Col sm={12} className="text-right">
        <Button className="ctm-btn btn-sm" color="primary">Save</Button>
      </Col>
    </div>
  </Form>
  );
};

export default AddCourier;

import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Col
} from 'reactstrap';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    brand_name: '',
    company_name: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.brand_name || !formData.company_name || !formData.email || !formData.mobile || !formData.password || !formData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Fields Empty',
        text: 'Please fill in all fields!',
      });
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_BASE_MERCHANT_REGISTER, formData);
      Swal.fire({ title: 'Registered Successfully', text: response.data.msg, icon: 'success' });
      navigate('/');
       // handle response from the API
    } catch (error) {
      Swal.fire({ title: 'Error', text: error.response.data.msg, icon: 'error' });
      console.error('Error:', error);
    }
  };
  return (
    <div className="pt-4 pb-6" style={{backgroundColor:'#172b4d'}}>
      <Container className="px-4 d-flex align-items-center">
        <Col lg="7" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-3">
              <div className="text-muted text-center mt-2 mb-3">
                <h1 style={{color:'#8898aa'}}>Sign Up</h1>
                <h2 style={{color:'#8898aa'}}>Merchant Registration Form</h2>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <p>Sign Up with credentials</p>
              </div>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Brand Name (amazon, flipkart)"
                      type="text"
                      name="brand_name"
                      value={formData.brand_name}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Registered Company Name"
                      type="text"
                      name="company_name"
                      autoComplete="new-email"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Email"
                      name='email'
                      type="text"
                      autoComplete="new-email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                    name='mobile'
                      placeholder="Mobile"
                      type="text"
                      autoComplete="new-email"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                    name='password'
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="new-password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      name='confirmPassword'
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col>
        <img
            alt="..."
            src={
                require("../../assets/img/brand/doc.png")
            }
            />
        </Col>
        </Container>
        </div>
    );
  };
  
  export default Register;
  
import React, { useState,useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import './CreateOrder.css'
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

const CreateOrder = () => {

  const [loader, setLoader] = useState(false);
  const [datas,setDatas]=useState([]);
  const merchant_id=Cookies.get('merchant_id');

    const [formData, setFormData] = useState({
        freightCollection: '',
        pickupType: '',
        pickupLocation: '',
        productDescription: '',
        totalWeight: '',
        clientReferenceId: '',
        quantity: '',
        unitOfMeasurement: 'centimeter', // Default unit of measurement
        amountCollection: 'Prepaid', // Default amount collection type
        amountToCollect: 0,
        shipperGSTIN: '',
        consigneeGSTIN: '',
        sendToType: 'pincode', // Default send to type
        sendToValue: '',
        invoiceAmount: '',
        ewayValue: '',
        invoiceNumber: '',
        newAddressData: {
          company: '',
          contactPerson: '',
          name: '',
          phoneNumber: '',
          address: '',
          pinCode: '',
          city: '',
          state: ''
        },
        invoiceDocument: null,
        supportingDocuments: [],
        shipmentDetail: [],
        merchant_id:merchant_id,
        newAddressData: {}, 
        isPreviewVisible: false    
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = (name === 'invoiceAmount' || name==='amountToCollect' || name==='totalWeight') && value !== '' ? parseFloat(value) : value;
        setFormData({
          ...formData,
          [name]: parsedValue
        });
      };
      
      const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
          ...formData,
          [name]: files[0]
        });
      };

      const handleSupportingDocumentChange = (index, e) => {
        const { value } = e.target;
        const updatedSupportingDocuments = [...formData.supportingDocuments];
        updatedSupportingDocuments[index].type = value;
        setFormData({
          ...formData,
          supportingDocuments: updatedSupportingDocuments
        });
      };

      const addSupportingDocument = () => {
        setFormData({
          ...formData,
          supportingDocuments: [...formData.supportingDocuments, { type: '', file: null }]
        });
      };

      const handleSupportingFileChange = (index, e) => {
        const { files } = e.target;
        const updatedSupportingDocuments = [...formData.supportingDocuments];
        updatedSupportingDocuments[index].file = files[0];
        setFormData({
          ...formData,
          supportingDocuments: updatedSupportingDocuments
        });
      };

      const uploadInvoiceDocument = () => {
        // Logic to upload invoice document
      };
    
      const uploadSupportingDocument = (index) => {
        // Logic to upload supporting document at the specified index
      };
    
      const toggleNewAddressModal = () => {
        setFormData({
          ...formData,
          newAddressModal: !formData.newAddressModal
        });
      };
    
      const handleNewAddressInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          newAddressData: {
            ...formData.newAddressData,
            [name]: value
          }
        });
      };
    
      const saveNewAddress = () => {
        toggleNewAddressModal();
      };

      const [formDataN, setFormDataN] = useState([{}]);
      const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
      const [createButtonClickCount, setCreateButtonClickCount] = useState(0);

      const handleInputChange2 = (index, event) => {
        const { name, value } = event.target;
        const updatedFormData = [...formDataN];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [name]: value
        };
        setFormDataN(updatedFormData);
      };

     const handleRecreateForm = () => {
  // Check if the totalQuantity matches the quantity of the last form section
  const isButtonDisabled = totalQuantity === formDataN[formDataN.length - 1]?.[`quantity-${formDataN.length - 1}`];

  // Set the initial value of the quantity field in the new form section to totalQuantity
  const newFormData = { quantity: totalQuantity };
  setFormDataN(prevFormData => [...prevFormData, newFormData]);
  setCreateButtonClickCount(prevCount => prevCount + 1);
  setCreateButtonDisabled(isButtonDisabled); // Set the disabled state of the button
};

      
      const handleDeleteForm = index => {
        setFormDataN(prevFormData => {
          const updatedFormData = [...prevFormData];
          updatedFormData.splice(index, 1);
          return updatedFormData;
        });
      };

    
      const [maxForms, setMaxForms] = useState(0);

      const handleSubmit = () => {

        const validationRules = {
          freightCollection: value => !!value,
          pickupType: value => !!value,
          pickupLocation: value => !!value,
          productDescription: value => !!value,
          totalWeight: value => !!value && !isNaN(parseFloat(value)),
          clientReferenceId: value => !!value,
          quantity: value => !!value && !isNaN(parseInt(value)),
          unitOfMeasurement: value => !!value,
          length: value => !!value,
          breadth: value => !!value,
          height: value => !!value,
          width: value => !!value,
          amountCollection: value => !!value,
          amountToCollect: value => !!value && !isNaN(parseFloat(value)),
          shipperGSTIN: value => !!value,
          consigneeGSTIN: value => !!value,
          sendToType: value => !!value,
          sendToValue: value => !!value,
          invoiceAmount: value => !!value && !isNaN(parseFloat(value)),
          invoiceValue: value => !!value && !isNaN(parseFloat(value)),
          ewayValue: value => !!value,
          invoiceNumber: value => !!value,
          newAddressData: {
            storeCode: value => !!value,
            company: value => !!value,
            contactPerson: value => !!value,
            name: value => !!value,
            phoneNumber: value => !!value,
            address: value => !!value,
            pinCode: value => !!value,
            city: value => !!value,
            state: value => !!value
          }
        }

           const validateNestedObject = (object, rules) => {
      for (const key in object) {
        if (rules[key]) {
          if (typeof rules[key] === 'function') {
            if (!rules[key](object[key])) {
              return false;
            }
          } else if (typeof rules[key] === 'object') {
            if (!validateNestedObject(object[key], rules[key])) {
              return false;
            }
          }
        }
      }
      return true;
    };
        // Handle form submission logic here
        // You can access formData to get all form values
      
        // Include the values from formDataN in formData
        const updatedFormData = {
          ...formData,
          dimensions: formDataN.map(data => ({
            count: Number(data[`quantity-${formDataN.indexOf(data)}`]),
            length:Number( data[`length-${formDataN.indexOf(data)}`]),
            width: Number(data[`breadth-${formDataN.indexOf(data)}`]),
            height: Number(data[`height-${formDataN.indexOf(data)}`])
          })),
          shipmentDetail: formDataO.map(data => ({
            clientReferenceId: data.clientReferenceId,
            count: data.quantity
          }))
        };
      
        console.log(updatedFormData);

        axios.post((`${process.env.REACT_APP_BASE_URL_SINGLE_PLACE_ORDER}`), updatedFormData)
        .then((res)=>{
          if(res){
            console.log(res,'ress')
          }
        })
        .catch((err)=>{
          console.log(err,'err')
        })
        
      
        // Here you can send the formData to your backend or perform any other actions
      };


      useEffect(()=>{
        axios({
          method: "post",
          url:`${process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_VIEW}/${merchant_id}`,
      }).then((res) => {
          setLoader(false);
          if (res.data) {
              setDatas(res.data)
              // console.log(datas,'dats')
          }
          else {
              Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
          }
      }).catch((err) => {
          setLoader(false);
      });
      },[])


      const [formDataO, setFormDataO] = useState([
        { clientReferenceId: '', quantity: '' } // Initial form data
      ]);

      const handleInputChange3 = (index, event) => {
        const { name, value } = event.target;
        const fieldName = name.split('-')[0]; // Extracting the field name from the dynamic name
        const updatedFormData1 = [...formDataO];
        updatedFormData1[index] = {
          ...updatedFormData1[index],
          [fieldName]: value // Update the corresponding field based on the extracted name
        };
        setFormDataO(updatedFormData1);
        console.log(updatedFormData1); // Check if formDataO is updated correctly
      };

      const addFormBtn = () => {
        setFormDataO([...formDataO, { clientReferenceId: '', quantity: '' }]);
      };
    
      // Function to delete a replicated form section
      const deleteFormBtn = (index) => {
        if (index === 0) return; // Prevent deletion of the first form
        const updatedFormData1 = [...formDataO];
        updatedFormData1.splice(index, 1);
        setFormDataO(updatedFormData1);
      };

      const totalQuantity = formDataO.reduce((total, form) => total + parseInt(form.quantity || 0), 0);

      // console.log(totalQuantity,'tt')

            useEffect(() => {
        if (totalQuantity>=2) {
          setMaxForms(parseInt(totalQuantity));
        }
      }, [totalQuantity]);

    
      return (
        <div className="container mt-4 mr-8">
      <div className="row">
        <div className="col-md-6">
          
        <Card className="">
      <CardBody>
        <CardTitle tag="h4">Pickup Location</CardTitle>
        <FormGroup>
          <Label for="pickupLocation">Select Pickup Location</Label>
          <Input className="custom-select col-12 form-select form-select" type="select" name="pickupLocation" id="pickupLocation" value={formData.pickupLocation} onChange={handleInputChange}>
            <option value="">Select Pickup Location</option>
            {datas.map(item => (
              <option key={item.id} value={`${item.contact_name}`}>
                {console.log(item,'item')}
                {item.pickup_pincode} - {item.pickup_address_1}{item.pickup_address_2}
              </option>
            ))}
          </Input>
        </FormGroup>
      </CardBody>
    </Card>
<Card>
  <CardBody>
    <CardTitle tag="h4">Freight Collection</CardTitle>
    <div className="row">
      <div className="col-md-12">
        <FormGroup tag="fieldset">
          <h4>Choose Option</h4>
          <div className='d-flex justify-content-between'>
          <FormGroup check className='col-6'>
            <Label check>
              <Input type="radio" name="freightCollection" value="billToClient" onChange={handleInputChange} />{' '}
              Bill to Client
            </Label>
          </FormGroup>
          <FormGroup check className='col-6'>
            <Label check>
              <Input type="radio" name="freightCollection" value="collectOnDelivery" onChange={handleInputChange} />{' '}
              Collect on Delivery (COD)
            </Label>
          </FormGroup>
          </div>
        </FormGroup>
      {formData.freightCollection === 'collectOnDelivery' && (
            <FormGroup tag="fieldset">
              <h4>Pickup Type</h4>
              <div className='d-flex justify-content-between'>
              <FormGroup check className='col-6'>
                <Label check>
                  <Input type="radio" name="pickupType" value="fmPickup" onChange={handleInputChange} />{' '}
                  Fm-pickup
                </Label>
              </FormGroup>
              <FormGroup check className='col-6'>
                <Label check>
                  <Input type="radio" name="pickupType" value="selfDrop" onChange={handleInputChange} />{' '}
                  Self-drop
                </Label>
              </FormGroup>
              </div>
            </FormGroup>
          )}
        </div>
    </div>
  </CardBody>
</Card>
<Card className="">
            <CardBody>
              <CardTitle tag="h4">Client Details</CardTitle>
              {formDataO.map((form, index) => (
                <div key={index}>
                  <div className="row">
                    <div className="col-md-8">
                      <FormGroup>
                        <Label for={`clientReferenceId-${index}`}>Client Reference ID/Order ID</Label>
                        <Input
                          type="text"
                          name={`clientReferenceId-${index}`}
                          id={`clientReferenceId-${index}`}
                          placeholder="Enter Client Reference ID/Order ID"
                          value={formDataO.clientReferenceId}
                          onChange={(e) => handleInputChange3(index, e)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label for={`quantity-${index}`}>Quantity</Label>
                        <Input
                          type="text"
                          name={`quantity-${index}`}
                          id={`quantity-${index}`}
                          placeholder="Enter Quantity"
                          value={formDataO.quantity}
                          onChange={(e) => handleInputChange3(index, e)}
                        />
                      </FormGroup>
                    </div>
                    {/* Conditionally render delete button */}
                    {index !== 0 && (
                      <div className="col-md-12">
                        <Button color="danger" onClick={() => deleteFormBtn(index)}>Delete</Button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <Button color="primary" onClick={addFormBtn}>Add Reference ID/Order ID</Button>
            </CardBody>
          </Card>
          <Card className="">
            <CardBody>
              <CardTitle tag="h4">Shipment Details</CardTitle>
              <p>Total no. of boxes:{totalQuantity===''?0:totalQuantity}</p>
              <div className='d-flex'>
              <div className="col-md-8">
              <FormGroup>
                <Label for="productDescription">Product Description</Label>
                <Input type="text" name="productDescription" id="productDescription" placeholder="Enter Product Description" value={formData.productDescription} onChange={handleInputChange} />
              </FormGroup>
              </div>
              <div className="col-md-4">
              <FormGroup>
                <Label for="totalWeight">Total Weight (Kg)</Label>
                <Input type="text" name="totalWeight" id="totalWeight" placeholder="Enter Total Weight" value={formData.totalWeight} onChange={handleInputChange} />
              </FormGroup>
              </div>
              </div>
            </CardBody>
          </Card>
          <Card className="">
            <CardBody>
              <div>
      {formDataN.map((data, index) => (
        <div key={index} className='measure-box'>
          {/* {console.log(index,'indexleft', data,'hii')} */}
          <FormGroup>
            <Label for={`quantity-${index}`}>Quantity</Label>
            <Input type="text" name={`quantity-${index}`} id={`quantity-${index}`} placeholder="quantity" value={data[`quantity-${index}`]} onChange={e => handleInputChange2(index, e)} />
          </FormGroup>
          <FormGroup>
            <Label for={`breadth-${index}`}>Length</Label>
            <Input type="text" name={`length-${index}`} id={`length-${index}`} placeholder="length" value={data[`length-${index}`]} onChange={e => handleInputChange2(index, e)} />
          </FormGroup>
          <FormGroup>
            <Label for={`height-${index}`}>Breadth</Label>
            <Input type="text" name={`breadth-${index}`} id={`breadth-${index}`} placeholder="breadth" value={data[`breadth-${index}`]} onChange={e => handleInputChange2(index, e)} />
          </FormGroup>
          <FormGroup>
            <Label for={`width-${index}`}>Height</Label>
            <Input type="text" name={`height-${index}`} id={`height-${index}`} placeholder="height" value={data[`height-${index}`]} onChange={e => handleInputChange2(index, e)} />
          </FormGroup>
          
          {index > 0 && ( // Show delete button after the first form
            <span className='deleteBtn float-right d-flex align-items-center p-0' color="danger" onClick={() => handleDeleteForm(index)}><i className="text-danger fa fa-trash" aria-hidden="true"></i></span>
          )}
        </div>
      ))}
      <Button className='border-0  float-right btn py-2 px-4' color="primary" onClick={handleRecreateForm} disabled={createButtonClickCount >= maxForms-1}>Create New Dimentions</Button>
    </div>
             
            </CardBody>
          </Card>
          </div>
          <div className="col-md-6">
          <Card>
            <CardBody>
              <CardTitle tag="h4">Invoice Details</CardTitle>
              <FormGroup>
                <Label for="amountCollection">Amount Collection</Label>
                <Input type="select" name="amountCollection" id="amountCollection" className="ml-3 custom-select col-5 form-select" value={formData.amountCollection} onChange={handleInputChange}>
                  <option value="Prepaid">Prepaid</option>
                  <option value="CoD">COD</option>
                </Input>
              </FormGroup>
              {formData.amountCollection === 'CoD' && (
                <FormGroup>
                  <Label for="amountToCollect">Amount to Collect</Label>
                  <Input type="number" name="amountToCollect" id="amountToCollect" placeholder="Enter Amount to Collect" value={formData.amountToCollect} onChange={handleInputChange} />
                </FormGroup>
              )}
              {formData.amountCollection === 'Prepaid' && (
                formData.amountToCollect === 0
              )}
              
            </CardBody>
            <Card className="">
            <CardBody>
              <div className='d-flex send-to inv-to'>
              <FormGroup>
                  <Label for="ewayValue">E-Waybill</Label>
                  <Input type="text" name="ewayValue" id="ewayValue" placeholder={`Enter ${formData.ewayValue}`} value={formData.ewayValue} onChange={handleInputChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="invoiceAmount">Amount</Label>
                  <Input type="text" name="invoiceAmount" id="invoiceAmount" placeholder={`Enter ${formData.invoiceAmount}`} value={formData.invoiceAmount} onChange={handleInputChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="invoiceNumber">Invoice Number</Label>
                  <Input type="text" name="invoiceNumber" id="invoiceNumber" placeholder={`Enter ${formData.invoiceNumber}`} value={formData.invoiceNumber} onChange={handleInputChange} />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
          </Card>
          <Card className="">
            <CardBody>
              <CardTitle tag="h4">Customer Details</CardTitle>
              <Button className='border-0 float-left btn py-2 px-4' color="primary" onClick={toggleNewAddressModal}>Add Client Address</Button>
            </CardBody>
          </Card>
          {formData.newAddressData ? (
  <div>
    <h3>Preview:</h3>
    <p>Company: {formData.newAddressData.company}</p>
    <p>Contact Person: {formData.newAddressData.contactPerson}</p>
    <p>Name: {formData.newAddressData.name}</p>
    <p>Phone Number: {formData.newAddressData.phoneNumber}</p>
    <p>Address: {formData.newAddressData.address}</p>
    <p>Pin Code: {formData.newAddressData.pinCode}</p>
    <p>City: {formData.newAddressData.city}</p>
    <p>State: {formData.newAddressData.state}</p>
  </div>
) : null}

          <Card className="">
  <CardBody>
    <CardTitle tag="h4">Upload Documents</CardTitle>
    <FormGroup>
      <Label for="invoiceDocument">Invoice</Label>
      <Input type="file" name="invoiceDocument" id="invoiceDocument" onChange={handleFileChange} />
    </FormGroup>
    <FormGroup>
      <Label for="supportingDocuments">Supporting Documents (Optional)</Label>
      {formData.supportingDocuments.map((document, index) => (
        <div key={index} className="mb-3">
          <Input type="file" onChange={(e) => handleSupportingFileChange(index, e)} />
          <Input type="select" value={document.type} onChange={(e) => handleSupportingDocumentChange(index, e)} className="ml-3 custom-select col-5 form-select">
            <option value="">Select Document Type</option>
            <option value="govtPhotoId">Govt. Photo ID</option>
            <option value="declarationOfGoods">Declaration Of Goods</option>
            {/* Add more options as needed */}
          </Input>
        </div>
      ))}
      <Button className='border-0  float-right btn btn-primary py-2 px-4' color="primary" onClick={addSupportingDocument}>Add Document</Button>
    </FormGroup>
  </CardBody>
</Card>

        </div>
      </div>
      <Modal isOpen={formData.newAddressModal} toggle={toggleNewAddressModal}>
        <ModalHeader toggle={toggleNewAddressModal}>Add Customer Details</ModalHeader>
        <ModalBody>
          {/* <FormGroup>
            <Label for="storeCode">Store Code</Label>
            <Input type="text" name="storeCode" id="storeCode" value={formData.newAddressData.storeCode} onChange={handleNewAddressInputChange} />
          </FormGroup> */}
         <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="company">Company</Label>
            <Input type="text" name="company" id="company" value={formData.newAddressData.company} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="contactPerson">Contact Person</Label>
            <Input type="text" name="contactPerson" id="contactPerson" value={formData.newAddressData.contactPerson} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" name="name" id="name" value={formData.newAddressData.name} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <Input type="text" name="phoneNumber" id="phoneNumber" value={formData.newAddressData.phoneNumber} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input type="text" name="address" id="address" value={formData.newAddressData.address} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="pinCode">Pin Code</Label>
            <Input type="text" name="pinCode" id="pinCode" value={formData.newAddressData.pinCode} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="city">City</Label>
            <Input type="text" name="city" id="city" value={formData.newAddressData.city} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="state">State</Label>
            <Input type="text" name="state" id="state" value={formData.newAddressData.state} onChange={handleNewAddressInputChange} />
          </FormGroup>
        </Col>
      </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveNewAddress}>Save</Button>{' '}
          <Button color="secondary" onClick={toggleNewAddressModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <button className='border-0  float-right btn py-2 px-4 mb-3' onClick={handleSubmit}>Submit</button>
    </div>
  )
}

export default CreateOrder

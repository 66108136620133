import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, CardTitle, Table } from 'reactstrap';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Loader from '../Footer/Loader';

const Allorders = () => {
  const merchant_id = Cookies.get('merchant_id');
  const [data, setData] = useState([]);
  const[loader, setLoader] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL_ALL_ORDERS}/${merchant_id}`);
      setLoader(false);
      setData(response?.data?.orders);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAddressInfo = (newAddressData) => {
    try {
      const addressData = JSON.parse(newAddressData);
      return {
        company: addressData.company,
        name: addressData.name
      };
    } catch (error) {
      console.error('Error parsing address data:', error);
      return {
        company: '',
        name: ''
      };
    }
  };

  const cancelOrder = async (orderwaybilno) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL_CANCEL_ORDER}`, {'waybill': orderwaybilno});
      Swal.fire('Success', 'Order cancelled successfully', 'success');
      fetchData();
    } catch (error) {
      console.error('Error cancelling order:', error);
    }
  };

  return (
    <div className='side-margin'>
      <Card>
        <CardBody>
      <CardTitle className='page-title'>All Orders</CardTitle>
      {loader ? <Loader /> : (
        <Table bordered responsive className="custom-table">
        <thead>
          <tr>
            <th>LR NO.</th>
            <th>MASTER WAYBILL</th>
            <th>CONSIGNEE</th>
            <th>MANIFESTED AT</th>
            <th>MANIFESTED ON</th>
            <th>INVOICES</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td><Link to={`/view-order/${item.id}`} className="link">{item.lr_no}</Link></td>
              <td>{item.master_awb}</td>
              <td>
                {getAddressInfo(item?.newAddressData).company}, {getAddressInfo(item?.newAddressData).name}</td>
              <td>{item?.pickupLocation}</td>
              <td>{item?.created_at}</td>
              <td>0</td>
              <td><Button color="danger" size="sm" onClick={() => cancelOrder(item.master_awb)}>Cancel</Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
      )}
  
    </CardBody>
      </Card>
  </div>
  );
};

export default Allorders;

import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Col, Card, CardBody, CardTitle } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import UrlImageDownloader from 'react-url-image-downloader';
import Loader from '../Footer/Loader';

const merchant_id = Cookies.get('merchant_id');

const BankDetails = () => {
    const [formData, setFormData] = useState({
        merchant_id: merchant_id,
        account_type: 'Saving',
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        branch: '',
        cancel_cheque: null // File input field
    });

    const [isLoading, setIsLoading] = useState(true);
    const [disableForm, setDisableForm] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_MERCHANT_BANK_DETAILS_VIEW}`, { merchant_id: merchant_id });
            const data = response.data.data[0];
            console.log(data, 'data');
            if (data) {
                setFormData({
                    ...formData,
                    account_type: data.account_type || 'Saving',
                    bank_name: data.bank_name || '',
                    account_number: data.account_number || '',
                    ifsc_code: data.ifsc_code || '',
                    branch: data.branch || '',
                    cancel_cheque: data.cancel_cheque_copy || null
                });
                setDisableForm(true);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
        console.log(formData, 'formdata')
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, cancel_cheque: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataCopy = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            formDataCopy.append(key, value);
        });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_MERCHANT_BANK_DETAILS}`, formDataCopy, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                Swal.fire({ title: 'Success', text: 'Form submitted successfully', icon: 'success' });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire({ title: 'Error', text: 'Failed to submit form', icon: 'error' });
        }
    };

    return (
        <div className='side-margin'>
            <Card>
                <CardBody>
                <CardTitle className='page-title'>Bank Details</CardTitle>     
            {isLoading ? (
                <Loader />
            ) : (
                <Form onSubmit={handleSubmit} disabled={disableForm}>
                    <FormGroup row>
                        <Col sm={6}>
                            <Label for="account_type">Account Type</Label>
                            <Input className='form-control' type="select" name="account_type" id="account_type" value={formData.account_type} onChange={handleChange} disabled={disableForm}>
                                <option value="Saving">Saving</option>
                                <option value="Current">Current</option>
                            </Input>
                        </Col>
                        <Col sm={6}>
                            <Label for="bank_name">Bank Name</Label>
                            <Input type="text" name="bank_name" id="bank_name" value={formData.bank_name} onChange={handleChange} disabled={disableForm} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={6}>
                            <Label for="account_number">Account Number</Label>
                            <Input type="text" name="account_number" id="account_number" value={formData.account_number} onChange={handleChange} disabled={disableForm} />
                        </Col>
                        <Col sm={6}>
                            <Label for="ifsc_code">IFSC Code</Label>
                            <Input type="text" name="ifsc_code" id="ifsc_code" value={formData.ifsc_code} onChange={handleChange} disabled={disableForm} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        
                        <Col sm={6}>
                            <Label for="branch">Branch</Label>
                            <Input type="text" name="branch" id="branch" value={formData.branch} onChange={handleChange} disabled={disableForm} />
                        </Col>
                        <Col sm={6}>
                            <Label for="cancel_cheque">Cancel Cheque</Label>
                            <Input type="file" name="cancel_cheque" id="cancel_cheque" onChange={handleFileChange} disabled={disableForm} />
                        </Col>
                        <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + formData?.cancel_cheque} />
                        <Col>
                            <Button disabled={disableForm} color="primary" type="submit">Submit</Button>
                        </Col>
                    </FormGroup>
                </Form>
            )}
            </CardBody>
            </Card>
        </div>
    );
};

export default BankDetails;

import React, { useEffect, useState } from 'react';
import './Modal.css';
import { Modal, ModalHeader, ModalBody, Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, Form, Input, FormGroup, Card, CardBody, CardTitle } from 'reactstrap';
import AddMerchant from './AddMerchant'; // Import the AddMerchant component
import axios from 'axios';
import Swal from 'sweetalert2';
import Status from '../Status';
import EditMerchant from './EditMerchant';
const configData = require("../../config");



const Merchant = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [viewId, setViewId] = useState('');
  const [data,setData]=useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    fetchDataPage();
  }, [page]);

  const fetchDataPage = async () => {
    try {
      console.log(page, 'page');
      const response = await axios.post(`${process.env.REACT_APP_BASE_MERCHANT_LIST}?page=${page}`);
      console.log(response.data.data);
      setData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };


  // const [viewData,setViewData]=useState([]);
  const [searchData,setSearchData]=useState({
    company_name: '',
    email: '',
    id: ''
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModal2 = (id) => {
    setViewId(id);
    setModalOpen2(!modalOpen2);
  };

  useEffect(() => {
    const fetchList =()=>{
      axios.post(`${process.env.REACT_APP_BASE_MERCHANT_LIST}`)
    .then((res) => {
      setData(res.data.data);
      if (res) {
        // Swal.fire({ title: 'Saved Successfully', text: res.msg, icon: 'Success' }); 
      } else {
        Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
      }
    })
    .catch((err) => {
      console.error('Error:', err);
    });
    }
    fetchList();
  },[])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  // const editMerchant=(id)=>{
  //   axios.post(`${process.env.REACT_APP_IMAGE_MERCHANT_VIEW}`,{ id:id })
  //   .then((res) => {
  //     setViewData(res.data.data);
  //     console.log(viewData)
  //     if (res) {
  //       //Swal.fire({ title: 'Saved Successfully', text: res.msg, icon: 'Success' });
  //     } else {
  //       Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
  //     }
  //   })
  //   .catch((err) => {
  //     console.error('Error:', err);
  //   });
  // }
  const handleSearch = async(e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    console.log(searchData, 'search data');
  try {
    let url = '';
    if (name === 'company_name') {
      url = (`${process.env.REACT_APP_BASE_MERCHANT_LIST}?company_name=${value}`);
    } else if (name === 'email') {
      url = (`${process.env.REACT_APP_BASE_MERCHANT_LIST}?email=${value}`);
    } else if (name === 'id') {
      url = (`${process.env.REACT_APP_BASE_MERCHANT_LIST}?id=${value}`);
    }
    console.log(url, 'url');
    const response = await axios.post(url);
    setData(response.data.data);
    console.log(response.data, 'responseda'); // Handle the response data accordingly
  } catch (error) {
    console.error('Error searching:', error);
    // Handle error
  }
};

const handleStatusToggle = (id, currentStatus) => {
  const newStatus = currentStatus === "1" ? "0" : "1";
  // Make an API call to update the status
  axios.post(process.env.REACT_APP_BASE_MERCHANT_STATUS, { id: String(id), status: newStatus })
    .then((res) => {
      // Handle success if needed
      console.log("Status updated successfully:", res.data);
      // You might want to update the local data with the new status
      const updatedData = data.map(item => {
        if (item.id === id) {
          return { ...item, status: newStatus };
        }
        return item;
      });
      setData(updatedData);
    })
    .catch((err) => {
      // Handle error
      console.error('Error updating status:', err);
    });
};
const handleNextPage = () => {
  setPage(page + 1);
};

const handlePrevPage = () => {
  setPage(page - 1);
};

  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(true);
  return (
    <div className='side-margin'>
      <Card>
        <CardBody>
      <CardTitle className='page-title'>All Orders</CardTitle>
      <Form>
        <FormGroup className='mb-3 d-flex justify-content-between searchfiltered mb-3 align-items-center'>
          <Input name='company_name' value={searchData.company_name} type='text' placeholder='Enter Brand Name' onChange={handleSearch} />
          <Input style={{marginLeft:'10px'}} name='email' value={searchData.email} type='text' placeholder='Enter Email Address' onChange={handleSearch} />
          <Input style={{marginLeft:'10px'}} type='text' name='id' value={searchData.id} placeholder='Enter Merchant ID' onChange={handleSearch} />
          <Button style={{whiteSpace: 'nowrap',marginLeft:'15px'}} onClick={toggleModal}>Add Merchant</Button>
        </FormGroup>
      </Form>
      <Table bordered responsive className="custom-table">
      <thead>
        <tr>
          <th width="1%">ID</th>
          <th width="3%">COMPANY NAME</th>
          <th width="3%">MERCHANT EMAIL</th>
          <th width="3%">REGISTERED DATE</th>
          <th width="3%">TRADING DATE</th>
          <th width="3%">ACCOUNT TYPE</th>
          <th width="3%">LIMIT/BALANCE</th>
          <th width="5%">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.id}</td>
            <td>{item.company_name}</td>
            <td>{item.email}</td>
            <td>{item.created_at}</td>
            <td>{item.updated_at}</td>
            <td>{configData.account_type[item.billing]}</td>
            <td>{item.wallet_balance}</td>
            <td className="text-nowrap">
                <div className="btn-group-ctm">
                <span className="mr-2"><Status
                      statusUrl={process.env.REACT_APP_BASE_MERCHANT_STATUS}
                      data={{ "id": String(item.id), "status": item.status }}
                      onClick={() => handleStatusToggle(item.id, item.status)}
                    /></span>
                <Button className="ctm-btn btn-sm ml-2" color="info" onClick={() => toggleModal2(item.id)} outline>Edit</Button>
                </div>
            </td>
            
          </tr>
        ))}
      </tbody>
    </Table>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Merchant</ModalHeader>
        <ModalBody>
          <AddMerchant toggleModal={toggleModal} /> {/* Include the AddMerchant component */}
        </ModalBody>
      </Modal>
      <Modal isOpen={modalOpen2} toggle={toggleModal2}>
        <ModalHeader toggle={toggleModal2}>Edit Merchant</ModalHeader>
        <ModalBody>
          <EditMerchant viewId={viewId} toggleModal={toggleModal2} /> {/* Include the AddMerchant component */}
        </ModalBody>
      </Modal>
      <div className='pagination-box float-right pt-3'>
      <Button color="primary" onClick={handlePrevPage} disabled={page === 1}>
        Prev
      </Button>
      <span className="mx-2">Page {page} of {totalPages}</span>
      <Button color="primary" onClick={handleNextPage} disabled={page === totalPages}>
        Next
      </Button>
    </div>
      </CardBody>
    </Card>
    </div>
  );
};

export default Merchant;

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Dashboard from './components/Dashboard/Dashboard';
import { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Allorders from './components/Allorders/Allorders';
import CreateOrder from './components/CreateOrder/CreateOrder';
import PickupPoints from './components/PickupPoints/PickupPoints';
import CreatePickupPoints from './components/PickupPoints/CreatePickupPoints';
import EditPickupPoint from './components/PickupPoints/EditPickupPoint';
import Merchant from './components/Merchants/Merchant';
import AddMerchant from './components/Merchants/AddMerchant';
import EditMerchant from './components/Merchants/EditMerchant';
import Courier from './components/Couriers/Courier';
import BankDetails from './components/BankDetails/BankDetails';
import BillingDetails from './components/BillingDetails/BillingDetails';
import KycDetails from './components/KycDetails/KycDetails';
import Serviceability from './components/Serviceability/Serviceability';
import Downloads from './components/Downloads';
import Zoning from './components/Zoining/Zoning';
import ViewOrder from './components/CreateOrder/ViewOrder';
import Label from './components/Label/Label';
import Cookies from 'js-cookie';
import AdminLogin from './components/AdminLogin/AdminLogin';
import Noresult from './components/Noresult';
import Forget from './components/Forget/Forget';

function App() {
  const isAuthenticated = Cookies.get('token') !== undefined;
  console.log(isAuthenticated, 'isAuthenticated');
  return (
    <div className={isAuthenticated ? 'App-with-sidebar' : 'App'}>
      <BrowserRouter>
        <Header />
        {isAuthenticated && <Sidebar />}
        {isAuthenticated ? (
        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/' element={<Dashboard />} />
          <Route path='/all-orders' element={<Allorders />} />
          <Route path='/create-order' element={<CreateOrder />} />
          <Route path='/view-order/:id' element={<ViewOrder />} />
          <Route path='/pickup-points' element={<PickupPoints />} />
          <Route path='/pickup-points/add' element={<CreatePickupPoints />} />
          <Route path='/pickup-points/edit/:valId' element={<EditPickupPoint />} />
          <Route path='/merchant' element={<Merchant />} />
          <Route path='/create-merchant' element={<AddMerchant/>} />
          <Route path='/edit-merchant' element={<EditMerchant />} />
          <Route path='/courier' element={<Courier />} />
          <Route path='/bank-details' element={<BankDetails />} />
          <Route path='/billing-details' element={<BillingDetails />} />
          <Route path='/kyc-details' element={<KycDetails />} />
          <Route path='/serviceability' element={<Serviceability />} />
          <Route path='/downloads' element={<Downloads />} />
          <Route path='/zoning/:courierId' element={<Zoning />} />
          <Route path='/label' element={<Label />} />
          </Routes>
          ) : (
            <Routes>
               <Route path='/admin' element={<AdminLogin />} />
            <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} />
            <Route path='*' element={<Noresult />} />
            <Route path='/forget' element={<Forget />} />
            </Routes>
          )}
      </BrowserRouter>
    </div>
  );
}

export default App;

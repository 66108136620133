import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from 'sweetalert2';
import { StateList } from '../../StateList';

const CreatePickupPoints = () => {

    const [loader, setLoader] = useState(false);
    const [pickupDetailsDisabled, setPickupDetailsDisabled] = useState(false);
    const [pickupDetailsDisabled2, setPickupDetailsDisabled2] = useState(false);
    const [datas,setDatas]=useState([]);
    
    var getStatus = Cookies.get('status');
    var statusValue = getStatus === 'true' ? '1' : '0';

    const [formData, setFormData] = useState({
        contact_name:'',
        pickup_address_1: '',
        pickup_address_2: '',
        pickup_pincode: '',
        pickup_city_name: '',
        pickup_state_id: '',
        pickup_phone: '',
        check: false,
        pickup_details_for_label: '',
        pickup_details_for_label2: '',
        return_address_1: '',
        return_address_2: '',
        return_pincode: '',
        return_city_name: '',
        return_state_id: '',
        return_phone: '',
        check: false,
        return_details_for_label: '',
        merchant_id:Cookies.get('merchant_id'),
        status:statusValue,
        pickup_state_name:'',
        contact_email:'',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(()=>{
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_FROM_LOGISTIFY}`,
          data: {'logistify_merchant_id':Cookies.get('logistify_merchant_id')},
      }).then((res) => {
          setLoader(false);
          console.log(res?.data?.data);
          if (res?.data?.data!==0) {
              setDatas(res?.data?.data)
          }
          else {
              Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
          }
      }).catch((err) => {
          setLoader(false);
      });
      },[])

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit=()=>{
        console.log(formData);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_ADD}`,
            data: formData,
        }).then((res) => {
            setLoader(false);
            if (res) {
                Swal.fire({ title: 'Saved Successfully', text: res.msg, icon: 'Success', })
            }
            else {
                Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
            }
        }).catch((err) => {
            setLoader(false);
        });
    }

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            // Copy pickup details to return details
            setFormData(prevState => ({
                ...prevState,
                return_address_1: formData.pickup_address_1,
                return_address_2: formData.pickup_address_2,
                return_pincode: String(formData.pickup_pincode),
                return_city_name: formData.pickup_city_name,
                return_state_id: formData.pickup_state_id,
                return_phone: formData.pickup_phone,
                pickup_details_for_label2:formData.pickup_details_for_label
            }));
        } else {
            // Clear return details
            setFormData(prevState => ({
                ...prevState,
                return_address_1: '',
                return_address_2: '',
                return_pincode: '',
                return_city_name: '',
                return_state_id: '',
                return_phone: '',

            }));
        }
    };

    const handleCheckboxChange2 = (e) => {
        const { name, checked } = e.target;
        setPickupDetailsDisabled(checked);
    };

    const handleCheckboxChange3 = (e) => {
        const { name, checked } = e.target;
        setPickupDetailsDisabled2(checked);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        console.log(item, 'item');
        setSelectedItem(item.contact_name);
        setFormData({
            contact_name: item.contact_name,
            pickup_address_1: item.pickup_address_1,
            pickup_address_2: item.pickup_address_2,
            pickup_pincode: String(item.pickup_pincode),
            pickup_city_name: item.pickup_city_name,
            pickup_state_id: item.pickup_state_id,
            pickup_phone: item.pickup_phone,
            pickup_details_for_label: item.pickup_details_for_label,
            return_address_1: item.return_address_1 || '', // Handle null values if any
            return_address_2: item.return_address_2 || '',
            return_pincode: String(item.return_pincode) || '',
            return_city_name: item.return_city_name || '',
            return_state_id: item.return_state_id || '',
            return_phone: item.return_phone || '',
            return_details_for_label: item.return_details_for_label || '',
            merchant_id: Cookies.get('merchant_id'),
            status: statusValue,
            pickup_state_name: '',
            contact_email: item.contact_email || '', // Handle null values if any
        });
    };
  return (
    <div className='side-margin'>
      <Container style={{maxWidth:'none'}}>
            <Row className="justify-content-center">
                <Col sm="12">
                    <Card className="p-0 my-3 addPickupPoints content-wrapper border-0">
                        <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                            <div className="page-title mb-0"><h2 style={{color:'#6e6b7b'}}>Add New Pickup Points</h2></div>
                            
                        </CardHeader>
                        <CardBody className="forms">
                            <Form className='pl-2'>
                                <Row>
                                <FormGroup className='mr-3'>
                                    <Input type="text" name="contact_name" id="contactName" placeholder="Enter Full Name" value={formData.contact_name} onChange={handleChange} />
                                </FormGroup>
                                <FormGroup className='mr-3'>
                                    <Input type="text" name="contact_email" id="contactEmail" placeholder="Enter Email" value={formData.contact_email} onChange={handleChange} />
                                </FormGroup>
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret>
                                    {selectedItem ? selectedItem : 'Select Pickup Point'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    {datas && datas.map((item, index) => (
                                        <DropdownItem key={index} onClick={() => handleItemClick(item)}>{item.contact_name}</DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Row>
                    <Col md="6">
            <Card className="shadow border-0">
                <CardHeader className="d-flex justify-content-between">
                    <div className="card-title"><b>Pickup Details</b></div>
                    <div><small>(Will be Shared with Courier)</small></div>
                </CardHeader>
                <CardBody className="pt-0">
                    <Form>
                        <FormGroup>
                            <Label for="address1_pickup">Enter Address Line 1<span className="text-danger">*</span></Label>
                            <Input type="text" name="pickup_address_1" id="address1_pickup" placeholder="Address Line 1" value={formData.pickup_address_1} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="address2_pickup">Enter Address Line 2</Label>
                            <Input type="text" name="pickup_address_2" id="address2_pickup" placeholder="Address Line 2" value={formData.pickup_address_2} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="pincode_pickup">Enter Pincode<span className="text-danger">*</span></Label>
                            <Input name="pickup_pincode" type="text" id="pincode_pickup" placeholder="Pincode" value={formData.pickup_pincode} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="city_pickup">Enter City Name<span className="text-danger">*</span></Label>
                            <Input type="text" name="pickup_city_name" id="city_pickup" placeholder="City Name" value={formData.pickup_city_name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="state_pickup">Select State<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                                <Input 
                                    type="select" 
                                    name="pickup_state_id" 
                                    id="state_pickup" 
                                    value={formData.pickup_state_id} 
                                    onChange={handleSelectChange}
                                    className="form-control"
                                >
                                    <option value="">Select State</option>
                                    {StateList.map(state => (
                                        <option key={state.state_id} value={state.state_id}>{state.state_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="contact_pickup">Enter Mobile Number<span className="text-danger">*</span></Label>
                            <Input type="text" name="pickup_phone" id="contact_pickup" placeholder="Mobile" value={formData.pickup_phone} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup className="mb-3 form-check">
                            <Input name="pickupDetailsCheckbox" id="sameCheck" type="checkbox" className="form-check-input" onChange={handleCheckboxChange2} />
                            <Label for="sameCheck" className="form-check-label">Same as in address label <small>(Paste on label)</small></Label>
                        </FormGroup>

                        <FormGroup>
                            <Label for="pickupDetails_pickup">Pickup Details</Label>
                            <Input name="pickup_details_for_label" 
                                disabled={pickupDetailsDisabled} 
                                type="textarea" 
                                id="pickupDetails_pickup" 
                                placeholder="Pickup Details For address label" 
                                value={formData.pickup_details_for_label} 
                                onChange={handleChange} 
                            />
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        </Col>
                        <Col md="6">
                            <Card className="shadow border-0">
                            <CardHeader className="d-flex justify-content-between">
                                <div className="card-title"><b>Return Details</b></div>
                                <div>
                                    <div className="form-check">
                                        <Input type="checkbox" name="check" id="sameasPickup" className="form-check-input" onChange={handleCheckboxChange} />
                                        <Label for="sameasPickup" className="form-check-label">Same as Pickup Details</Label>
                                    </div>
                                </div>
                            </CardHeader>
                                <CardBody className="pt-0">
                                <Form>
                        <FormGroup>
                            <Label for="return_address_1">Enter Address Line 1<span className="text-danger">*</span></Label>
                            <Input type="text" name="return_address_1" id="return_address_1" placeholder="Address Line 1" value={formData.return_address_1} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="return_address_2">Enter Address Line 2</Label>
                            <Input type="text" name="return_address_2" id="return_address_2" placeholder="Address Line 2" value={formData.return_address_2} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="return_pincode">Enter Pincode<span className="text-danger">*</span></Label>
                            <Input name="return_pincode" type="text" id="return_pincode" placeholder="Pincode" value={formData.return_pincode} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="return_city_name">Enter City Name<span className="text-danger">*</span></Label>
                            <Input type="text" name="return_city_name" id="return_city_name" placeholder="City Name" value={formData.return_city_name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="return_state_id">Select State<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                                <Input 
                                    type="select" 
                                    name="return_state_id" 
                                    id="return_state_id" 
                                    value={formData.return_state_id} 
                                    onChange={handleSelectChange}
                                    className="form-control"
                                >
                                    <option value="">Select State</option>
                                    {StateList.map(state => (
                                        <option key={state.state_id} value={state.state_id}>{state.state_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="return_phone">Enter Mobile Number<span className="text-danger">*</span></Label>
                            <Input type="text" name="return_phone" id="return_phone" placeholder="Mobile" value={formData.return_phone} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup className="mb-3 form-check">
                            <Input name="check" id="exampleCheck1" type="checkbox" className="form-check-input" onChange={handleCheckboxChange3} />
                            <Label for="exampleCheck1" className="form-check-label">Same as in address label <small>(Paste on label)</small></Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pickupDetails_pickup2">Pickup Details</Label>
                            <Input name="pickup_details_for_label2" 
                                disabled={pickupDetailsDisabled2} 
                                type="textarea" 
                                id="pickupDetails_pickup2" 
                                placeholder="Pickup Details For address label" 
                                value={formData.pickup_details_for_label2} 
                                onChange={handleChange} 
                            />
                        </FormGroup>
                    </Form>
                                </CardBody>
                            </Card>
                            <Button onClick={handleSubmit} color="primary" className='float-right mt-3 px-5'>Save</Button>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default CreatePickupPoints

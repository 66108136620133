import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row,Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';

const ViewOrder = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const params = useParams();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL_VIEW_ORDER}/${params.id}`);
        // Set the fetched order data to the state
        setOrderDetails(response.data);
        setOrder(response.data.order);
        console.log(response)
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    // Call the fetchData function only once when the component mounts
    fetchData();
  }, [params.id]); // Add params.id as a dependency to useEffect to re-fetch data when the id changes

  if (!order) {
    return (
      <div className='side-margin'>
        <h1>Loading...</h1>
      </div>
    );
  }
  const handleClick = () => {
    // Data you want to send
    const dataToSend = {
      orderData:orderDetails
    };

    // Navigate to the '/label' route and pass data as state
    navigate('/label', { state: { data: dataToSend } });
  };
  const addressData = JSON.parse(order?.newAddressData);
  // Render the order details once they are fetched
  return (
    <div className='side-margin'>
    <Container className='m-0'>
      <Card className="order-detail">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle caret>
        Print
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem >SHIPPING LABEL (A4 size)</DropdownItem>
        <DropdownItem >SHIPPING LABEL ("4"x"2")</DropdownItem>
        <DropdownItem >SHIPPING LABEL ("4"x"2.5")</DropdownItem>
        <DropdownItem onClick={handleClick}>SHIPPING LABEL ("3"x2")</DropdownItem>
      </DropdownMenu>
    </Dropdown>
        <CardHeader>
          <strong>Order Details</strong>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h4>LR No. : {order.lr_no}</h4>
              <img alt="" src="./assets/images/surface.png" width="60px" />
            </Col>
            <Col md={6}>
              <dl>
                <dt>Charged Weight</dt>
                <dd><span className="bold font-20">{order.totalWeight} Kg</span></dd>
              </dl>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <dl>
                <dt>Master AWB:</dt>
                <dd><strong>{order.master_awb}</strong></dd>
              </dl>
              <dl>
                <dt>Type</dt>
                <dd>{order.amountCollection} (Multi - Shipment)</dd>
              </dl>
            </Col>
            <Col md={6}>
              <dl>
                <dt>No. of boxes</dt>
                <dd><strong>{order.dimensions.length}</strong></dd>
              </dl>
              <dl>
                <dt>Invoice</dt>
                <dd><strong>{order.invoiceNumber}</strong></dd>
              </dl>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <dl>
                <dt>E-Waybills</dt>
                <dd><strong>{order.ewayValue}</strong></dd>
              </dl>
              <Button color="primary">+ EDIT INVOICE</Button>
            </Col>
            <Col md={6}>
              <dl>
                <dt>Pick up</dt>
                <dd>{new Date(order.created_at).toLocaleString()}</dd>
              </dl>
              <Button color="secondary" disabled name="gst">GST Details</Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="bulk-upload download-disable">
                <Button color="secondary" className="m-r-10 bulk-upload-btn" name="download">Download</Button>
                <div className="bulk-sub-items top-wo-pod">
                  <ul>{/* Add your list items here */}</ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <dl>
                <dt>Receiver</dt>
                <dd><strong>{addressData.company}</strong> {addressData.address}, {addressData.city}, {addressData.pinCode}.</dd>
              </dl>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
    </div>
  );
};

export default ViewOrder;

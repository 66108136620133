import React from 'react'
import Sidebar from '../Sidebar/Sidebar'

const Dashboard = () => {
  return (
    <>
    
    </>
  )
}

export default Dashboard

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, CardHeader, Table, Button, Label, Input } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { states } from '../../config';
import { useParams } from 'react-router-dom';

const EditPickupPoint = () => {
    const merchant_id = Cookies.get('merchant_id');

    const [loader, setLoader] = useState(false);
    const [formDatas, setFormDatas] = useState({});
    const [formData, setFormData] = useState({
        contact_name: '',
        mobile: '',
        pin: '',
        address: '',
    });
    const { valId } = useParams();

    useEffect(() => {
        setLoader(true);
        axios.post(`${process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_SINGLE}`, { id: valId })
            .then(res => {
                setLoader(false);
                if (res && res.data) {
                    const pickupPoint = res.data[0];
                    setFormDatas(pickupPoint); // Assuming response data is an array and you want the first element
                    setFormData({
                        contact_name: pickupPoint.contact_name,
                        mobile: pickupPoint.pickup_phone,
                        pin: pickupPoint.pickup_pincode,
                        address: pickupPoint.pickup_address_1,
                    });
                }
            })
            .catch(error => {
                setLoader(false);
                console.error('Error fetching data:', error);
            });
    }, [valId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_BASE_MERCHANT_PICKUP_POINTS_EDIT}`, formData)
        .then((res)=>{
            if(res){
                console.log(res, 'res');
            }
        })
        // Handle form submission here
        console.log('Form submitted with data:', formData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };


    return (
        <div className='side-margin'>
            <Card className="shadow border-0">
                <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                    <CardTitle className="page-title"><b>View Pickup Points</b></CardTitle>
                    <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                        {/* Add navigation buttons or elements if needed */}
                    </div>
                </CardHeader>
                <CardBody className="pt-0">
                    <Row className='editForm-table'>
                        <Col sm={6}>
                            <CardHeader className="px-0"><CardTitle><b>Pickup Details</b></CardTitle></CardHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th className="border-0">Pickup Address 1 :</th>
                                        <td>{formDatas.pickup_address_1}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup Address 2 :</th>
                                        <td>{formDatas.pickup_address_2}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup Pincode :</th>
                                        <td>{formDatas.pickup_pincode}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup City :</th>
                                        <td>{formDatas.pickup_city_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup State :</th>
                                        <td>{states[formDatas.pickup_state_id]}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup Mobile :</th>
                                        <td>{formDatas.pickup_phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm={6}>
                            <CardHeader className="px-0"><CardTitle><b>Return Details</b></CardTitle></CardHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Return Address 1 :</th>
                                        <td>{formDatas.return_address_1}</td>
                                    </tr>
                                    <tr>
                                        <th>Return Address 2 :</th>
                                        <td>{formDatas.return_address_2}</td>
                                    </tr>
                                    <tr>
                                        <th>Return Pincode :</th>
                                        <td>{formDatas.return_pincode}</td>
                                    </tr>
                                    <tr>
                                        <th>Return City :</th>
                                        <td>{formDatas.return_city_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Return State :</th>
                                        <td>State Name</td>
                                    </tr>
                                    <tr>
                                        <th>Return Mobile :</th>
                                        <td>{formDatas.return_phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <div className='editForm'>
    <Form className='mt-2' onSubmit={handleSubmit}>
            <Col sm="12">
                <FormGroup>
                    <Label for='contact_name'>Pickup Point/Contact Name</Label>
                    <Input type="text" name="contact_name" id="contact_name" value={formData.contact_name} onChange={handleChange} />
                </FormGroup>
            </Col>
        <Row className='p-0 m-0'>
            <Col md="6">
                <FormGroup>
                    <Label for='mobile'>Mobile Number</Label>
                    <Input type="text" name='mobile' id='mobile' value={formData.mobile} onChange={handleChange} />
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    <Label for='pin'>Pin Code</Label>
                    <Input type="text" name='pin' id='pin' value={formData.pin} onChange={handleChange} />
                </FormGroup>
            </Col>
        </Row>
            <Col sm="12">
                <FormGroup>
                    <Label for='address'>Address</Label>
                    <Input type="textarea" name='address' id='address' value={formData.address} onChange={handleChange} />
                </FormGroup>
            </Col>
        <Row className="mt-3">
            <Col sm="12" className="text-right">
                <Button color="primary" type="submit">Save</Button>
            </Col>
        </Row>
    </Form>
</div>
                </CardBody>
            </Card>
        </div>
    );
};

export default EditPickupPoint;

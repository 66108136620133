
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col,NavItem,NavLink,NavbarBrand,Navbar,UncontrolledCollapse,Nav } from "reactstrap";
import './Header.css';
import Cookies from "js-cookie";
const Header = () => {
  const navigate = useNavigate();
  const token = Cookies.get('token');
  const name = Cookies.get('name');
  const logoutFunction=()=>{
    window.location.reload();
    Cookies.remove('token');
    Cookies.remove('user-type');
    Cookies.remove('merchant_id');
    Cookies.remove('logistify_merchant_id');
    Cookies.remove('status');
    Cookies.remove('name');
    navigate('/');
  }
  return (
    <>
      <div className="header bg-gradient-info">
        <Container fluid>
          <div className="header-body">
          <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand className="mr-5" to="/" tag={Link}>
            <img
              alt="..."
              src={require("../../assets/img/brand/logo5.png")}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("../../assets/img/brand/logo1.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
                {token ? (
                  <>
                   <form
            className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto global-search"
          >
            <div className="form-group mb-0">
              <div className="input-group input-group-alternative bg-white">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
                <input className="form-control" placeholder="LR No./Order No./Mobile No." type="text" />
              </div>
            </div>
          </form>
                  <NavItem>
                <NavLink className="nav-link-icon" to="/" tag={Link}>
                  <i className="ni ni-planet" />
                  <span className="nav-link-inner--text">Dashboard</span>
                </NavLink>
              </NavItem>
                <NavItem>
                    <NavLink className="nav-link-icon" onClick={logoutFunction} to="/" tag={Link}>
                      <i className="ni ni-key-25" />
                      <span className="nav-link-inner--text">Logout</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/admin/user-profile"
                  tag={Link}
                >
                  <i className="ni ni-single-02" />
                  <span className="nav-link-inner--text">{name}</span>
                </NavLink>
              </NavItem>
                  </>
                ):(
                  null
                )}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Col, Card, CardBody, CardTitle } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import UrlImageDownloader from 'react-url-image-downloader';
import { StateList } from '../../StateList';
import Loader from '../Footer/Loader';

const merchant_id = Cookies.get('merchant_id');

const BillingDetails = () => {
    const [formData, setFormData] = useState({
        merchant_id: merchant_id,
        address:  '',
        pincode: '',
        city: '',
        state_id: '',
        contact_number: '',
        email: '',
    });

    const [isLoading, setIsLoading] = useState(true);
    const [disableForm, setDisableForm] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_MERCHANT_BILLING_DETAILS_VIEW}`, { merchant_id: merchant_id });
            const data = response.data.data;
            console.log(data, 'data');
            if (data) {
                setFormData({
                    ...formData,
                    merchant_id: merchant_id,
                    address: data.address || '',
                    pincode:data.pincode || '',
                    city: data.city || '',
                    state_id: data.state_id || '',
                    contact_number: data.contact_number || '',
                    email: data.email || '',
                });
                setDisableForm(true);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
        console.log(formData, 'formdata')
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleStateChange = (e) => {
        const stateId = e.target.value;
        setFormData({ ...formData, state_id: stateId });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataCopy = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            formDataCopy.append(key, value);
        });
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_MERCHANT_BILLING_DETAILS}`, formDataCopy);
            if (response.status === 200) {
                Swal.fire({ title: 'Success', text: 'Form submitted successfully', icon: 'success' });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire({ title: 'Error', text: 'Failed to submit form', icon: 'error' });
        }
    };

    return (
        <div className='side-margin'>
            <Card>
                <CardBody>
                    <CardTitle className='page-title'>Billing Details</CardTitle>
            {isLoading ? (
                <Loader />
            ) : (
                <Form onSubmit={handleSubmit} disabled={disableForm}>
                    <FormGroup row>
                        <Col sm={6}>
                            <Label for="bank_name">Address</Label>
                            <Input type="text" name="address" id="address" value={formData.address} onChange={handleChange} disabled={disableForm} />
                        </Col>
                        <Col sm={6}>
                            <Label for="account_number">Pincode</Label>
                            <Input type="text" name="pincode" id="pincode" value={formData.pincode} onChange={handleChange} disabled={disableForm} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Col sm={6}>
                        <Label for="state_id">State</Label>
                        <select disabled={disableForm} className="form-control" name="state_id" id="state_id" value={formData.state_id} onChange={handleStateChange}>
                            <option value="">Select State</option>
                            {StateList.map((item, index) => (
                                <option key={index} value={item.state_id}>{item.state_name}</option>
                                ))
                                }
                        </select>
                        </Col>
                        <Col sm={6}>
                            <Label for="branch">City</Label>
                            <Input type="text" name="city" id="city" value={formData.city} onChange={handleChange} disabled={disableForm} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Button disabled={disableForm} color="primary" type="submit">Submit</Button>
                        </Col>
                    </FormGroup>
                </Form>
                
            )}
            </CardBody>
                </Card>
        </div>
    );
};

export default BillingDetails;

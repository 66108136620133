import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Spinner
  } from "reactstrap";
import Swal from "sweetalert2";
  
  const Login = () => {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

const handleLogin=()=>{
  const loginData = {
    email: email,
    password: password
  };
  // console.log(loginData);
  axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_MERCHANT_LOGIN}`,
    data: loginData,
}).then((res) => {
    setLoader(false); 
    window.location.reload();
    console.log(res,'loginres')
    Cookies.set('token',res?.data?.accessToken);
    Cookies.set('name',res?.data?.data?.brand_name);
    Cookies.set('user-type',res?.data?.data?.user_type);
    Cookies.set('merchant_id',res?.data?.data?.id);
    Cookies.set('logistify_merchant_id',res?.data?.data?.logistify_merchant_id);
    Cookies.set('status',res?.data?.data?.status);
    if (res) {
      // Set loading state to true
      setLoader(true);
    
      // Hide the loader and navigate after a delay
      setTimeout(() => {
        // Navigate based on the condition
        navigate(res?.data?.data?.user_type === "Merchant" ? '/' : '/');
        
        // Set loading state to false
        setLoader(false);
      }, 1000); // Adjust the delay as needed
    }
    else {
        Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
    }
}).catch((err) => {
  console.log(err)
  Swal.fire({ title: 'Try Again', text: err, icon: 'error', })
    setLoader(false);
});
  setEmail('');
  setPassword('')
}
    return (
      <>

<div className="pt-4 pb-6" style={{backgroundColor:'#172b4d'}}>
<Container className="px-4 d-flex align-items-center">
          <Col lg="7" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-3">
              <div className="text-muted text-center mt-2 mb-3">
                <h1 style={{color:'#8898aa'}}>Sign in </h1>
                <h2 style={{color:'#8898aa'}}>Welcome to Logistics !</h2>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
              <p>Sign in with credentials</p>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                </div>
                <div className="text-center">
                {loader ? (
                      <Button className="my-4" color="primary" type="button" disabled>
                          <Spinner size="sm" color="light" /> Loading...
                      </Button>
                  ) : (
                      <Button onClick={handleLogin} className="my-4" color="primary" type="button">
                          Sign in
                      </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
            <Link
                className="text-light"
                to='/forget'
              >
                <p>Forgot password?</p>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link
                className="text-light"
                to='/register'
              >
                <p>Create new account</p>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col>
        <img
            alt="..."
            src={
                require("../../assets/img/brand/doc.png")
            }
            />
        </Col>
        </Container>
        </div>
      </>
    );
  };
  
  export default Login;
  
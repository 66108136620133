import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Noresult = () => {
  return (
    <div>
      <Container className="side-margin" style={{marginTop: '100px',textAlign: 'center'}}>
      <Row>
        <Col>
          <h1>No Results Found</h1>
          <p>Sorry, no results were found for your query.</p>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Noresult

import React from 'react';
import { Button, Spinner } from 'reactstrap';

const Loader = () => {
  return (
    <div>
      <Button color="primary" disabled>
        <Spinner size="sm" />
        <span style={{ marginLeft: '5px' }}>Loading Data</span>
      </Button>
    </div>
  );
};

export default Loader;

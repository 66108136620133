import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';

const Downloads = () => {
    const [data,setData]=useState([]);
    const baseURL = process.env.REACT_APP_BASE_URL;
    useEffect(()=>{
        const fetchDownloads = async ()=>{
            const res=axios.post(process.env.REACT_APP_BASE_URL_ADMIN_DOWNLOADLIST,{admin_id:1})
            .then((res)=>{
                setData(res.data.data)
                console.log(res.data.data)
            })
            .catch((err)=>{
                console.log(err)
            })
        }
        fetchDownloads();
    },[])
  return (
    <div className='side-margin'>
      <Card>
        <CardBody>
          <CardTitle className='page-title'>Downloads</CardTitle>
      <Table>
        <thead>
          <tr>
            <th>SNO.</th>
            <th>DATA TYPE</th>
            <th>DESCRIPTION</th>
            <th>DOWNLOAD ON</th>
            <th>USER</th>
            <th>FILTER</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.description}</td>
              <td>{item.description}</td>
              <td>{item.created_at}</td>
              <td>{item.admin.name}</td>
              <td>{item.filter}</td>
              <td>
                <Link className='btn p-2' to={`${baseURL}${item.file_name}`} download>Download</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
        </CardBody>
      </Card>
    </div>
  )
}
export default Downloads

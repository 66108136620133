import axios from 'axios';
import UrlImageDownloader from "react-url-image-downloader";
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import Swal from 'sweetalert2';
const configData = require("../../config");


const EditCourier = (props) => {
    console.log(props, "props")
    const [viewData, setViewData] = useState(null);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        brand_name: '',
        tat_in_days: '',
        brandlogo: null,
        weight_category: '',
        movement_type_id: '',
        volumetric_value: '',
        max_liability_per_shipment: '',
        status: '1',
        service_id:"1",
      });
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
        axios.post(`${process.env.REACT_APP_BASE_COURIER_VIEW}`, { id: props.viewId })
        .then((res) => {
          if (res) {
            const data = res.data.data;
            setFormData({
                ...data,
                billing: data.billing || "1"
            });
            setViewData(data);
          } else {
            Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
          }
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (viewData) {
      setFormData({
        id: String(props.viewId),
        email: viewData.email,
        weight_category:viewData.weight_category,
        brand_name: viewData.brand_name,
        max_liability_per_shipment: viewData.max_liability_per_shipment,
        status: String(viewData.status) || viewData.status,
        tat_in_days: viewData.tat_in_days,
        volumetric_value: viewData.volumetric_value,
        movement_type_id: viewData.movement_type_id,
        service_id:"1",
        logo:viewData.logo
      });
    }
  }, [viewData]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === 'logo') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0], // Store the file object itself
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };


const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataCopy = new FormData();
  // Append each field to formDataCopy
  Object.entries(formData).forEach(([key, value]) => {
      formDataCopy.append(key, value);
  });
  console.log(formDataCopy, "formDataCopy")
  try {
      axios.post(`${process.env.REACT_APP_BASE_COURIER_EDIT}`, formDataCopy, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      })
      .then((res) => {
          if (res) {
              Swal.fire({ title: 'Success', text: 'Data submitted successfully', icon: 'success' });
          } else {
              Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
          }
      })
  } catch (error) {
      console.error('Error submitting data:', error);
      Swal.fire({ title: 'Error', text: 'Failed to submit data', icon: 'error' });
  }
};



  return (
    <Form onSubmit={handleSubmit} className="" noValidate>
      <div className="mx-0 justify-content-start addModal row">
      <Col sm={4}>
        <FormGroup>
          <Label for="Courier Name">Enter Courier Name<span className="text-danger">*</span></Label>
          <Input type="text" name="full_name" id="Courier Name" placeholder="Enter Courier Name*" value={formData.full_name} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="email">Enter Email<span className="text-danger">*</span></Label>
          <Input type="email" name="email" id="email" placeholder="Enter Email*" value={formData.email} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="brand_name">Enter Brand Name<span className="text-danger">*</span></Label>
          <Input type="text" name="brand_name" id="brand_name" placeholder="Enter Brand Name*" value={formData.brand_name} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="tat_in_days">TAT In Days<span className="text-danger">*</span></Label>
          <Input type="text" name="tat_in_days" id="tat_in_days" placeholder="Enter TAT In Days*" value={formData.tat_in_days} onChange={handleChange} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="logo">Upload Logo<span className="text-danger">*</span></Label>
          <Input type="file" name="logo" id="logo" onChange={handleChange} />
          <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_COURIER + formData.logo} />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="selectWeightCategory">Weight Category <small>(In Kgs.)</small><span className="text-danger">*</span></Label>
          <Input type="select" className="form-control" name="weight_category" id="selectWeightCategory" value={formData.weight_category} onChange={handleChange}>
            <option value="">Select Weight Category</option>
            {Object.entries(configData.weight).map(([key, value]) => (
                <option value={value}>{value}</option>
            ))}
          </Input>
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="selectMovementType">Movement Type<span className="text-danger">*</span></Label>
          <Input type="select"  className="form-control" name="movement_type_id" id="selectMovementType" value={formData.movement_type_id} onChange={handleChange}>
          <option value="">Selcet Movement Type</option>
                {Object.entries(configData.movement_type).map(([key, value]) => (
                    <option value={key}>{value}</option>
                ))}
          </Input>
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="volumetric_value">Enter Volumetric Value<span className="text-danger">*</span></Label>
          <Input type="text" name="volumetric_value" id="volumetric_value" placeholder="Enter Volumetric Value*" value={formData.volumetric_value} onChange={handleChange} />
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label for="maxLiaShip">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
          <Input type="text" name="max_liability_per_shipment" id="maxLiaShip" placeholder="Enter Max Liability Shipment*" value={formData.max_liability_per_shipment} onChange={handleChange} />
          
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Status<span className="text-danger">*</span></Label>
          <div className='form-check d-flex flex-column'>
            <span className="form-check2 mr-2">
              <Input type="radio" name="status" id="active" value="1" checked={formData.status === '1'} onChange={handleChange} />
              <Label className="mx-2" for="active">Active</Label>
            </span>
            <span className="form-check2">
              <Input type="radio" name="status" id="inactive" value="0" checked={formData.status === '0'} onChange={handleChange} />
              <Label className="mx-2" for="inactive">Inactive</Label>
            </span>
          </div>
        </FormGroup>
      </Col>
      <Col sm={12} className="text-right">
        <Button className="ctm-btn btn-sm" color="primary">Save</Button>
      </Col>
    </div>
    </Form>
  );
};

export default EditCourier;

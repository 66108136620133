import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import axios from 'axios';
import './Modal.css';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const AddMerchant = ({ toggleModal }) => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    ship_status: "0",
    admin_id:'1',
    document_status: '0',
    company_name: '',
    email: '',
    brand_name: '',
    mobile: '',
    wallet_balance: '',
    max_liability_per_shipment: '',
    max_cod_value_per_order: '',
    password: '',
    confirm_password: '',
    remittance_tat_period: '',
    billing: '',
    freight_type: '',
    status: '0',
    logo: null,
    logistify_id:'',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === 'logo') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0], // Store the file object itself
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
    console.log(formData, 'form data');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
  
      axios.post(`${process.env.REACT_APP_BASE_MERCHANT_CREATE}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if (res) {
          console.log(res.data.data);
          Cookies.set('logistify_merchant_id',res?.data?.data?.logistify_merchant_id)
          Swal.fire({ title: 'Saved Successfully', text: res.msg, icon: 'Success' });
        } else {
          Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error' });
        }
      })
      .catch((err) => {
        console.error('Error:', err);
      });
    }
  };
  
  const validateForm = () => {
    let isValid = true;
    let errorMessage = '';
    // Validation rules for each field
    const validationRules = {
      company_name: 'Company Name',
      email: 'Email',
      brand_name: 'Brand Name',
      mobile: 'Mobile Number',
      wallet_balance: 'Wallet Balance',
      max_liability_per_shipment: 'Max Liability Shipment',
      max_cod_value_per_order: 'Max COD Value per Order',
      password: 'Password',
      confirm_password: 'Confirm Password',
      remittance_tat_period: 'Remittance TAT Period',
      billing: 'Billing',
      freight_type: 'Freight Type',
      status: 'Status'
    };
  
    // Iterate through each field and validate
    for (const fieldName in formData) {
      const value = formData[fieldName];
      if (typeof value === 'string' && !value.trim()) {
        isValid = false;
        errorMessage = `Please fill all required fields. ${validationRules[fieldName]} is required.`;
        break; // Exit loop early if any field is empty
      }
    }
  
    // Show validation error if any
    if (!isValid) {
      Swal.fire({ title: 'Error', text: errorMessage, icon: 'error' });
    }
  
    return isValid;
  };

  useEffect(() => {
    // Function to fetch data from the API when email changes
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_MERCHANT_CHECKMERCHANT}`, { email: formData.email });
        console.log(response.data.data);
        // Update form data with fetched data
        setFormData(prevData => ({
          ...prevData,
          ...response.data.data,
          logistify_id: response.data.data.id,
          // Assuming the response data has the same structure as formData
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors if needed
      }
    };

    // Check if email is not empty and call the API
    if (formData.email.trim() !== '') {
      fetchData();
    }
  }, [formData.email]);
  
  return (
    <Form onSubmit={handleSubmit} className="" noValidate>
      <div className="mx-0 justify-content-start addModal row">
        <FormGroup className="col-sm-4">
          <Label for="companyName_add">Enter Company Name<span className="text-danger">*</span></Label>
          <Input type="text" name="company_name" id="companyName_add" placeholder="Enter Company Name*" value={formData.company_name} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="email_add">Enter Email<span className="text-danger">*</span></Label>
          <Input type="email" name="email" id="email_add" placeholder="Enter Email*" value={formData.email} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="brandName_add">Enter Brand Name<span className="text-danger">*</span></Label>
          <Input type="text" name="brand_name" id="brandName_add" placeholder="Enter Brand Name*" value={formData.brand_name} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="contact_add">Enter Mobile Number<span className="text-danger">*</span></Label>
          <Input type="text" name="mobile" id="contact_add" placeholder="Enter Phone Number*" value={formData.mobile} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="walletBalance_add">Enter Wallet Balance<span className="text-danger">*</span></Label>
          <Input type="text" name="wallet_balance" id="walletBalance_add" placeholder="Enter Wallet Balance*" value={formData.wallet_balance} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="maxLiabShip_add">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
          <Input type="text" name="max_liability_per_shipment" id="maxLiabShip_add" placeholder="Enter Max Liability Shipment*" value={formData.max_liability_per_shipment} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="maxLiabShip_add">Enter Max COD Value <small>(per order)</small></Label>
          <Input type="text" name="max_cod_value_per_order" id="maxCodVal_add" placeholder="Enter Max COD Value*" value={formData.max_cod_value_per_order} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="password_add">Enter Password<span className="text-danger">*</span></Label>
          <div className="input-group">
            <Input type="password" name="password" id="password_add" placeholder="Enter Password*" value={formData.password} onChange={handleChange} />
            <div className="input-group-append">
              <span className="py-0 input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="confirmpass_add">Enter Confirm Password<span className="text-danger">*</span></Label>
          <div className="input-group">
            <Input type="password" name="confirm_password" id="confirmpass_add" placeholder="Enter Confirm Password*" value={formData.confirm_password} onChange={handleChange} />
            <div className="input-group-append">
              <span className="py-0 input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </span>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="logo">Upload Brand logo</Label>
          <Input type="file" name="logo" id="logo" accept=".jpg, .jpeg, .png" onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Label for="remittance_tat_period_add">Remittance TAT Period<span className="text-danger">*</span></Label>
          <Input type="text" name="remittance_tat_period" id="remittance_tat_period_add" placeholder="Enter Remittance TAT Period*" value={formData.remittance_tat_period} onChange={handleChange} />
        </FormGroup>
        <FormGroup className="col-sm-4">
<Label for="logistify_id">logistify Id</Label>
<Input placeholder='Enter logistify Id' className="mr-2" type="text" name="logistify_id" id="logistify_id" value={formData.logistify_id} onChange={handleChange} />
</FormGroup>
        {/* <FormGroup className="col-sm-4">
          <Label for="refer_by_add">Refer By</Label>
          <Input type="text" name="refer_by" id="refer_by_add" placeholder="Enter Refer By" value={formData.refer_by} onChange={handleChange} />
        </FormGroup> */}
        {/* <FormGroup className="col-sm-4">
          <Label for="additional_mrp_percentage_add">MRP Increase %</Label>
          <Input type="text" name="additional_mrp_percentage" id="additional_mrp_percentage_add" placeholder="Enter MRP Increase %" value={formData.additional_mrp_percentage} onChange={handleChange} />
        </FormGroup> */}
        <div className="col-sm-4 mb-3">
            <Label className="mb-1">Billing<span className="text-danger">*</span></Label>
            <div className="form-check2 d-flex flex-column">
                <div className="form-check">
                <Input className="mr-2" type="radio" name="billing" id="preBill_add" value="1" checked={formData.billing === '1'} onChange={handleChange} />
                <Label for="preBill_add" className="ml-1 mb-0">Prepaid Billing</Label>
                </div>
                <div className="form-check">
                <Input className="mr-2" type="radio" name="billing" id="postBill_add" value="2" checked={formData.billing === '2'} onChange={handleChange} />
                <Label for="postBill_add" className="ml-1 mb-0">Postpaid Billing</Label>
                </div>
            </div>
            </div>

            <FormGroup className="col-sm-4">
  <div className="pl-0 form-check d-flex flex-column">
    <Label className="mb-1">Freight Type<span className="text-danger">*</span></Label>
    <div className='form-check2 d-flex flex-column'>
      <div className="form-check">
        <Input className="mr-2" type="radio" name="freight_type" id="fixCharge_add" value="1" checked={formData.freight_type === '1'} onChange={handleChange} />
        <Label for="fixCharge_add" className="ml-1 mb-0">Fixed Charge</Label>
      </div>
      <div className="form-check">
        <Input className="mr-2" type="radio" name="freight_type" id="varCharge_add" value="2" checked={formData.freight_type === '2'} onChange={handleChange} />
        <Label for="varCharge_add" className="ml-1 mb-0">Variables Charge</Label>
      </div>
    </div>
  </div>
</FormGroup>
<FormGroup className="col-sm-4">
  <div className="pl-0 form-check d-flex flex-column">
    <Label className="mb-1">Status<span className="text-danger">*</span></Label>
    <div className='form-check2 d-flex flex-column'>
      <div className="form-check">
        <Input className="mr-2" type="radio" name="status" id="active_add" value="1" checked={formData.status === '1'} onChange={handleChange} />
        <Label for="active_add" className="ml-1 mb-0">Active</Label>
      </div>
      <div className="form-check">
        <Input className="mr-2" type="radio" name="status" id="inactive_add" value="0" checked={formData.status === '0'} onChange={handleChange} />
        <Label for="inactive_add" className="ml-1 mb-0">InActive</Label>
      </div>
    </div>
  </div>
</FormGroup>

        <FormGroup className="text-right col-sm-12">
          <Button className="btn-text btn-light mx-1">Save</Button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default AddMerchant;

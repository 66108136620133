import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';

const Label = () => {
    const [tableData, setTableData] = useState([]);
    const location = useLocation();


    useEffect(() => {
        const data = location.state?.data;
        setTableData(data);
    }, [location.state?.data]);
    
    const newAddressDataString = tableData?.orderData?.order?.newAddressData;
    let addressData = {};
    
    if (newAddressDataString) {
        try {
            addressData = JSON.parse(newAddressDataString);
        } catch (error) {
            console.error("Error parsing newAddressData:", error);
            // Handle parsing error if necessary
        }
    }


    console.log(tableData, 'data');
    const awbNosString = tableData?.orderData?.order?.awb_nos;
    const awbNosArray = awbNosString ? awbNosString.split(",") : [];
    const qrCode = awbNosArray.map((val)=>val);
    console.log(qrCode, 'qrCode');
    return (
        <div className='side-margin'>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            {awbNosArray.map((awb, index) => (
                <div style={{maxWidth:'300px'}} key={index}>
                    {/* Your component structure for each awb */}
                    <style>
                {`
                    @media print {
                        @page { margin:.1cm .1cm .1cm .0cm; padding: 0cm;}
                        body { font-family: sans-serif; }
                    }
                `}
            </style>
                    <Table className='table-wrapper-new' style={{color:'#000',background:'#fff',marginBottom:'30px',pageBreakAfter:'always'}}>
                        <tbody height='100px'>
                            <tr>
                                <td style={{ width: '30%', padding: '0px' }}>
                                    <div style={{marginTop:'25%'}} className="image-container">
                                    <QRCode value={qrCode[index]} size={56.69}/>
                                    </div>
                                    <div className='lm-pincode'>LM Pincode: {addressData?.pinCode}</div>
                                </td>
                                <td style={{ width: '70%', padding: '0px' }} className='table-content'>
                                    <div>LR:{tableData?.orderData?.order?.lr_no}</div>
                                    <div>MAWB: {awbNosArray[0]}</div>
                                    <div>{index === awbNosArray.length - 1 ? 
                                            // For the last item, display "Documents"
                                            "Documents" :
                                            // For other items, display the box count
                                            `Box Count - ${index + 1}/${awbNosArray.length-1}`
                                    }</div>
                                    <div>Client: KERYX B2B</div>
                                    <div>
                                        {index === awbNosArray.length - 1 ? 
                                            `DOC_${tableData?.orderData?.order?.lr_no}` :
                                            `${tableData?.orderData?.order?.counter[index]}`
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td height={66.69} style={{padding: '0px'}} colSpan="2">
                                    <div className="image-container-bar" style={{margin:'5% 0'}}>
                                        <Barcode value={qrCode[index]} height={46.69} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            ))}
            </div>
        </div>
    );
};

export default Label;
